
  import { Vue } from 'vue-class-component';
  import axios from 'axios';
  
  export default class Venues extends Vue {
  
  [x: string]: any;
    name: 'Venues' = 'Venues';
    allVenues: any;
    
  
  
    getAllVenues() {
      const  baseURL = process.env.VUE_APP_BACKEND_URL;
      document.title = 'Local Event Venues - VTIX';
  
      axios
        .get(
          baseURL+ '/api/v1/venues/'
        )
        .then((response) => {
          this.allVenues = response.data;
        });
    }
    mounted() {
      this.getAllVenues();
    }
    
    data() {
      return {
        allVenues: [],
  
      };
    }
  }
  