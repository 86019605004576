
import { Vue } from 'vue-class-component';
import axios from 'axios';
import callAPI from '@/axios-api';
import EventBox from '@/components/EventBox.vue';
import CustomToast from '@/components/CustomToast.vue';

export default class Events extends Vue {
  [x: string]: any;
  name: 'Events' = 'Events';
  events: any = [];
  data() {
    return {
      events: [],
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
      eventsPerPage: 0,
      eventPages: [],
      currentPage: 0,
      totalEvents: 0,
    };
  }
  getAllEvents() {
    this.$store.state.isLoading = true;
    const baseURL = process.env.VUE_APP_BACKEND_URL;
    document.title = 'Digital Tickets - VTIX';

    axios
      .get(baseURL + '/api/v1/events/?page_size=12')
      //add error handling
      .then((response) => {
        this.events = response.data;
        console.log(this.events);
        for (
          let i = 0;
          i < Math.ceil(this.events.count / this.events.results.length);
          i++
        ) {
          this.eventPages.push(i);
          console.log(i);
        }
        this.eventsPerPage = this.events.results.length;
        this.$store.state.isLoading = false;
      })
      .catch((error) => {
        this.events = [];
        this.$store.state.isLoading = false;
        this.toastInfo = {
          header: 'Error',
          message: 'Could not retrieve events. Please try again later.',
          smallText: error.message,
          show: true,
        };
      });
  }

  goToFirstPage() {
    this.currentPage = 0;
    this.goToPage(this.currentPage);
  }
  async goToPage(page: number) {
    this.currentPage = page;
    this.$store.state.isLoading = true;
    const url = `/api/v1/events/?page=${page + 1}&page_size=12`; // replace with your actual API endpoint
    try {
      const response = await callAPI.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.token}`,
        },
      });
      if (response.status === 200) {
        this.events = response.data;
        this.currentPage = page;

        this.$router.push({
          name: this.$route.name as string,
          query: { page: (this.currentPage + 1).toString() },
        });
      }
    } catch (error: any) {
      if (error.response.status == 401) {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
          .then((res: any) => {
            this.goToPage(page);
          });
      }
    } finally {
      this.$store.state.isLoading = false;
    }
  }
  async goToNextPage() {
    this.$store.state.isLoading = true;
    if (this.events.next) {
      const response = await axios.get(this.events.next, {
        headers: {
          Authorization: `JWT ${this.$store.state.token}`,
        },
      });
      try {
        this.events = response.data;
        this.currentPage++;
        this.$store.state.isLoading = false;
      } catch (error) {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
          .then((res: any) => {
            this.goToNextPage();
          });
      }
      this.events = response.data;
      this.$store.state.isLoading = false;
    }
  }
  async goToPreviousPage() {
    this.$store.state.isLoading = true;
    if (this.events.previous) {
      console.log(this.events.previous);
      const response = await axios.get(this.events.previous, {
        headers: {
          Authorization: `JWT ${this.$store.state.token}`,
        },
      });
      this.events = response.data;
      this.$store.state.isLoading = false;
      this.currentPage--;
    }
  }
  goToLastPage() {
    this.currentPage =
      Math.ceil(this.events.count / this.events.results.length) - 1;
    this.goToPage(
      Math.ceil(this.events.count / this.events.results.length) - 1
    );
  }
  goToMorePages() {
    if (this.currentPage + this.eventsPerPage <= this.eventPages.length) {
      this.goToPage(this.currentPage + this.eventsPerPage);
    }
  }
  mounted() {
    this.getAllEvents();
  }
}
