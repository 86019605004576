<template>
  <ul class="pagination pagination-split mt-0 float-right">
    <li class="page-item">
      <button
        class="page-link"
        :disabled="paginationData.currentPage === 0"
        @click="goToFirstPage"
        aria-label="First"
      >
        <span aria-hidden="true">««</span>
        <span class="sr-only">First</span>
      </button>
    </li>
    <li class="page-item">
      <button
        :disabled="paginationData.currentPage === 0"
        class="page-link"
        @click="goToPreviousPage"
        aria-label="Previous"
      >
        <span aria-hidden="true">«</span>
        <span class="sr-only">Previous</span>
      </button>
    </li>
    <li
      class="page-item"
      v-for="(page, key) in paginationData.records"
      :class="{ active: paginationData.currentPage === page }"
      :key="key"
      :if="
        page >=
          paginationData.currentPage - Math.floor(paginationData.maxTabs / 2) &&
        page <=
          paginationData.currentPage + Math.floor(paginationData.maxTabs / 2)
      "
    >
      <button class="page-link" @click="goToPage(page)">
        {{ page + 1 }}
      </button>
    </li>
    <li class="page-item">
      <button
        class="page-link"
        :disabled="
          paginationData.currentPage === paginationData.records.length - 1
        "
        v-if="
          paginationData.currentPage + paginationData.recordsPerPage <
          paginationData.records.length - 1
        "
        @click="goToMorePages"
        aria-label="Next"
      >
        <span aria-hidden="true">...</span>
        <span class="sr-only">Next</span>
      </button>
    </li>
    <li class="page-item">
      <button
        class="page-link"
        :disabled="
          paginationData.currentPage === paginationData.records.length - 1
        "
        @click="goToNextPage"
        aria-label="Next"
      >
        <span aria-hidden="true">»</span>
        <span class="sr-only">Next</span>
      </button>
    </li>
    <li class="page-item">
      <button
        class="page-link"
        :disabled="
          paginationData.currentPage === paginationData.records.length - 1
        "
        @click="goToLastPage"
        aria-label="Last"
      >
        <span aria-hidden="true">»»</span>
        <span class="sr-only">Last</span>
      </button>
    </li>
  </ul>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PaginationComp',

  props: {
    paginationData: {
      djangoResults: {
        type: Object,
        required: true,
        count: Number,
        next: Boolean,
        previous: String,
        results: Array,
      },

      currentPage: Number,
      recordsPerpage: Number,
      totalRecords: Number,
      apiEndPoint: String,
      baseURL: String,
      records: Array,
      pageSize: Number,
      maxTabs: Number,
    },
  },

  methods: {
    async getRecords() {
      this.$store.state.isLoading = true;
      const url = `${
        this.paginationData.baseURL + '/' + this.paginationData.apiEndPoint
      }?page_zie=${this.paginationData.pageSize}`; // replace with your actual API endpoint
      try {
        const response = await callAPI.get(url, {
          headers: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        });
        if (response.status === 200) {
          this.paginationData.djangoResults = response.data;
          this.paginationData.currentPage = 0;
          this.paginationData.records = response.data.results;
          this.paginationData.recordsPerpage = response.data.results.length;
          this.paginationData.totalRecords = response.data.count;
          this.paginationData.apiEndPoint = endPoint;
          this.$store.state.isLoading = false;
          for (
            let i = 0;
            i <
            Math.ceil(
              this.paginationData.djangoResults.count /
                this.paginationData.djangoResults.results.length
            );
            i++
          ) {
            this.records.push(i);
            console.log(i);
          }
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store
            .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
            .then((res) => {
              this.getRecords();
            });
        }
      } finally {
        this.$store.state.isLoading = false;
      }
    },

    goToFirstPage() {
      this.paginationData.currentPage = 0;
      this.goToPage(this.paginationData.currentPage);
    },

    async goToPage(page) {
      this.paginationData.currentPage = page;
      this.$store.state.isLoading = true;
      const url = `${
        this.paginationData.baseURL + '/' + this.paginationData.apiEndPoint
      }?page=${page + 1}`; // replace with your actual API endpoint
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        });
        if (response.status === 200) {
          this.paginationData.djangoResults = response.data;
          this.paginationData.currentPage = page;

          this.$router.push({
            name: this.$route.name,
            query: { page: (this.paginationData.currentPage + 1).toString() },
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 401) {
          this.$store
            .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
            .then((res) => {
              this.goToPage(page);
            });
        }
      } finally {
        this.$store.state.isLoading = false;
      }
    },
    async goToNextPage() {
      this.$store.state.isLoading = true;
      if (this.paginationData.djangoResults.next) {
        console.log(this.paginationData.djangoResults.next);
        const response = await axios.get(
          this.paginationData.djangoResults.next,
          {
            headers: {
              Authorization: `JWT ${this.$store.state.token}`,
            },
          }
        );
        console.log(this.$store.state.token);
        try {
          this.paginationData.djangoResults = response.data;
          this.paginationData.currentPage++;
          this.$store.state.isLoading = false;
        } catch (error) {
          if (error.response.status === 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
              .then((res) => {
                this.goToNextPage();
              });
          }
        }
        this.$store.state.isLoading = false;
      }
    },
    async goToPreviousPage() {
      this.$store.state.isLoading = true;
      if (this.paginationData.djangoResults.previous) {
        console.log(this.paginationData.djangoResults.previous);
        const response = await axios.get(
          this.paginationData.djangoResults.previous,
          {
            headers: {
              Authorization: `JWT ${this.$store.state.token}`,
            },
          }
        );
        this.paginationData.djangoResults = response.data;
        this.$store.state.isLoading = false;
        this.paginationData.currentPage--;
      }
    },

    goToLastPage() {
      this.paginationData.currentPage =
        Math.ceil(
          this.paginationData.djangoResults.count /
            this.paginationData.djangoResults.results.length
        ) - 1;
      this.goToPage(
        Math.ceil(
          this.paginationData.djangoResults.count /
            this.paginationData.djangoResults.results.length
        ) - 1
      );
    },

    goToMorePages() {
      if (
        this.paginationData.currentPage +
          this.paginationData.djangoResultsPerPage <=
        this.orderPages.length
      ) {
        this.goToPage(this.paginationData.currentPage + ordersPerPage);
      }
    },

    getMaxTabs() {
      let tabsToShow = 0;
      //If the value of tabs is 5, then show 5 tabs
      if (this.paginationData.maxTabs === 5) {
        tabsToShow = 5;
      }
    },
  },
};
</script>
