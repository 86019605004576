<style>
@media (min-width: 1025px) {
  .h-custom {
    height: 100vh !important;
  }
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.card-registration .select-arrow {
  top: 13px;
}

.bg-grey {
  background-color: #eae8e8;
}

@media (min-width: 992px) {
  .card-registration-2 .bg-grey {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

@media (max-width: 991px) {
  .card-registration-2 .bg-grey {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
</style>
<template>
  <MainToolbar />
  <div class="cart">
    <h1></h1>
  </div>
  <div class="row">
    <div class="col-md-12 mb-12">
      <div class="mb-12">
        <div class="container py-5 h-100">
          <div
            class="row d-flex justify-content-center align-items-center h-100"
          >
            <div class="col-12">
              <div
                class="card card-registration card-registration-2"
                style="border-radius: 15px"
              >
                <div class="card-body p-0">
                  <div class="row g-0">
                    <div class="col-lg-8">
                      <div class="p-5">
                        <div
                          class="d-flex justify-content-between align-items-center mb-5"
                        >
                          <h1 class="fw-bold mb-0 text-black">Shopping Cart</h1>
                          <h6 class="mb-0 text-muted">{{}} tickets</h6>
                        </div>
                        <hr class="my-4" />
                        <div
                          v-for="(ticket, index) in cart.items"
                          v-bind:key="index"
                        >
                          <div
                            class="row mb-4 d-flex justify-content-between align-items-center"
                          >
                            <div class="col-md-2 col-lg-2 col-xl-2">
                              <img
                                v-bind:src="ticket.event_image"
                                class="img-fluid rounded-3"
                                v-bind:alt="ticket.event_name"
                              />
                            </div>
                            <div class="col-md-3 col-lg-3 col-xl-3">
                              <h6 class="text-muted">
                                {{ ticket.event_name }}
                              </h6>
                              <h6 class="text-black mb-0">
                                {{ ticket.ticket_name }}
                              </h6>
                            </div>
                            <div class="col-md-3 col-lg-3 col-xl-2 d-flex">
                              <button
                                @click="decrementQuantity(index)"
                                class="btn btn-link px-2"
                              >
                                <i class="fas fa-minus"></i>
                              </button>

                              <input
                                id="form1"
                                min="0"
                                v-model="ticket.quantity"
                                :id="'quantity-' + ticket.id"
                                :name="'quantity-' + ticket.id"
                                type="number"
                                class="form-control form-control-sm"
                              />

                              <button
                                @click="incrementQuantity(index)"
                                class="btn btn-link px-2"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                            <div class="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                              <h6 class="mb-0">R {{ ticketTotal(ticket) }}</h6>
                            </div>
                            <div
                              @click="removeFromCart(index)"
                              class="col-md-1 col-lg-1 col-xl-1 text-end"
                            >
                              <a href="#!" class="text-muted"
                                ><i class="fas fa-times"></i
                              ></a>
                            </div>
                          </div>

                          <hr class="my-4" />
                        </div>

                        <div class="pt-5 justify-content-between">
                          <h6 class="mb-0">
                            <div class="text-end">
                              <button
                                @click="completeCheckout"
                                type="button"
                                class="btn bg-6 btn-block btn-lg"
                                data-mdb-ripple-color="dark"
                              >
                                Update Cart
                              </button>
                            </div>
                            <a href="#!" class="text-body" @click="goBack"
                              ><i class="fas fa-long-arrow-alt-left me-2"></i
                              >Back to shop</a
                            >
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 bg-grey">
                      <div class="p-5">
                        <h3 class="fw-bold mb-5 mt-2 pt-1">Summary</h3>
                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-4">
                          <h5 class="text-uppercase">tickets {{}}</h5>
                          <h5>R {{ cartTotal() }}</h5>
                        </div>

                        <h5 class="text-uppercase mb-3">Discount code</h5>

                        <div class="mb-5">
                          <div class="form-outline">
                            <input
                              type="text"
                              id="form3Examplea2"
                              class="form-control form-control-lg"
                            />
                            <label class="form-label" for="form3Examplea2"
                              >Enter your code</label
                            >
                          </div>
                        </div>

                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-5">
                          <h5 class="text-uppercase">Total price</h5>
                          <h5>R {{ cartTotal() }}</h5>
                        </div>

                        <button
                          @click="checkOutCart"
                          type="button"
                          class="btn bg-6 btn-block btn-lg"
                          data-mdb-ripple-color="dark"
                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import bootstrap javascript
import 'bootstrap';
import callAPI from '../axios-api';
document.title = 'Shopping Cart | VTIX';

export default {
  data() {
    return {
      cart: {
        items: [],
      },
    };
  },

  mounted() {
    this.cart = this.$store.state.cart;
  },
  methods: {
    updateCart(index, quantity) {
      let item = this.cart.items[index];
      item.quantity = quantity;
      this.$store.commit('addToCart', this.cart);
    },

    removeFromCart(index) {
      this.cart.items.splice(index, 1);
      this.$store.commit('removeFromCart', this.cart);
    },

    emptyCart() {
      this.cart.items = [];
      this.$store.commit('clearCart');
    },

    completeCheckout() {
      this.$store.commit('addToCart', this.cart);
    },

    formatPrice(price) {
      return price.toFixed(2);
    },

    formatCurrency(price) {
      return 'R ' + price.toFixed(2);
    },

    ticketTotal(ticket) {
      return ticket.quantity * ticket.price;
    },

    cartTotal() {
      let total = 0;
      this.cart.items.forEach((item) => {
        total += item.quantity * item.price;
      });
      return total;
    },

    decrementQuantity(index) {
      // Decrement the quantity for the ticket at the specified index
      if (this.cart.items[index].quantity > 0) {
        this.cart.items[index].quantity--;
      }
    },
    incrementQuantity(index) {
      // Increment the quantity for the ticket at the specified index
      this.cart.items[index].quantity++;
    },
    checkOutCart() {
      //this.createOrder();

      // Send the user to the checkout page
      if (this.cart.items.length > 0) {
        this.$store.commit('addToCart', this.cart);
        this.$router.push({ name: 'Payment' });
      } else {
        alert('Your cart is empty');
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    mapState() {
      return this.$store.state;
    },
  },
};
</script>
