<style scoped>
@media (min-width: 1025px) {
  .h-custom {
    height: 100vh !important;
  }
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.card-registration .select-arrow {
  top: 13px;
}

.bg-grey {
  background-color: #eae8e8;
}

@media (min-width: 992px) {
  .card-registration-2 .bg-grey {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

@media (max-width: 991px) {
  .card-registration-2 .bg-grey {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>
<template>
  <div class="cart">
    <h1></h1>
  </div>
  <div class="row">
    <div class="col-md-12 mb-4">
      <div class="mb-12">
        <div class="container py-5 h-100">
          <div
            class="row d-flex justify-content-center align-items-center h-100"
          >
            <div class="col-12">
              <div
                class="card card-registration card-registration-2"
                style="border-radius: 15px"
              >
                <div class="card-body p-0">
                  <div class="row g-0">
                    <div class="col-lg-8">
                      <div class="p-5">
                        <div
                          class="d-flex justify-content-between align-items-center mb-5"
                        >
                          <h1 class="fw-bold mb-0 text-black">
                            Billing Details
                          </h1>
                          <h6 class="mb-0 text-muted">
                            {{ cartItemCount }} tickets
                          </h6>
                        </div>
                        <hr class="my-4" />
                        <form id="customer-data">
                          <!-- 2 column grid layout with text inputs for the first and last names -->
                          <div class="row mb-4">
                            <div class="col">
                              <div class="form-outline">
                                <input
                                  type="text"
                                  id="firstname"
                                  class="form-control"
                                  v-model.lazy="customer.first_name"
                                />
                                <label class="form-label" for="firtname"
                                  >First name
                                  <span style="color: red">*</span></label
                                >
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-outline">
                                <input
                                  type="text"
                                  id="lastname"
                                  class="form-control"
                                  v-model.lazy="customer.last_name"
                                />
                                <label class="form-label" for="lastname"
                                  >Last name
                                  <span style="color: red">*</span></label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- Email input -->
                          <div class="form-outline mb-4">
                            <input
                              type="email"
                              id="form7Example5"
                              class="form-control"
                              v-model.lazy="customer.email"
                            />
                            <label class="form-label" for="form7Example5"
                              >Email <span style="color: red">*</span></label
                            >
                          </div>

                          <!-- Username input -->
                          <div class="form-outline mb-4">
                            <input
                              type="email"
                              id="username"
                              class="form-control"
                              v-model.lazy="customer.username"
                            />
                            <label class="form-label" for="username"
                              >Username <span style="color: red">*</span></label
                            >
                          </div>

                          <!-- Password input -->
                          <div class="form-outline mb-4">
                            <input
                              type="password"
                              id="password"
                              class="form-control"
                              v-model="customer.password"
                            />
                            <label class="form-label" for="password"
                              >Password <span style="color: red">*</span></label
                            >
                          </div>

                          <!-- Number input -->
                          <div class="form-outline mb-4">
                            <input
                              type="number"
                              id="form7Example6"
                              class="form-control"
                              v-model.lazy="customer.phone"
                            />
                            <label class="form-label" for="form7Example6"
                              >Phone <span style="color: red">*</span></label
                            >
                          </div>

                          <!-- Text input -->
                          <div class="form-outline mb-4">
                            <input
                              type="text"
                              id="address"
                              class="form-control"
                              v-model.lazy="customer.address"
                            />
                            <label class="form-label" for="address"
                              >Address</label
                            >
                          </div>
                          <!-- City input -->
                          <div class="row mb-4">
                            <div class="col">
                              <div class="form-outline">
                                <input
                                  type="text"
                                  id="city"
                                  class="form-control"
                                  v-model.lazy="customer.city"
                                />
                                <label class="form-label" for="city"
                                  >City</label
                                >
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-outline">
                                <input
                                  type="text"
                                  id="firstname"
                                  class="form-control"
                                  v-model.lazy="customer.post_code"
                                />
                                <label class="form-label" for="firtname"
                                  >Post Code</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- Province input -->
                          <div class="row mb-4">
                            <div class="col">
                              <div class="form-outline">
                                <input
                                  type="text"
                                  id="province"
                                  class="form-control"
                                  v-model="customer.province"
                                />
                                <label class="form-label" for="province"
                                  >Province</label
                                >
                              </div>
                            </div>
                          </div>

                          <!-- Checkbox 
                          <div
                            class="form-check d-flex justify-content-center mb-2"
                          >
                            <input
                              class="form-check-input me-2"
                              type="checkbox"
                              v-bind:checked="createAccount"
                            />
                            <label class="form-check-label" for="createAcc">
                              Create an account?
                            </label>
                          </div>

                          -->
                        </form>

                        <div class="pt-5">
                          <h6 class="mb-0">
                            <a href="#!" class="text-body"
                              ><i class="fas fa-long-arrow-alt-left me-2"></i
                              >Back to shop</a
                            >
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 bg-grey">
                      <div class="p-5">
                        <h3 class="fw-bold mb-5 mt-2 pt-1">Summary</h3>
                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-4">
                          <h5 class="text-uppercase">
                            ({{ cartItemCount }}) tickets
                          </h5>
                        </div>

                        <div
                          v-for="(ticket, index) in cart.items"
                          v-bind:key="index"
                          class=""
                        >
                          <div
                            class="list-group-item d-flex justify-content-between"
                          >
                            {{ ticket.ticket_name }}

                            ({{ ticket.quantity }})
                            <span>R {{ ticketTotal(ticket) }}</span>
                          </div>
                        </div>
                        <hr class="my-2" />
                        <div class="">
                          <div
                            class="list-group-item d-flex justify-content-between border-0 px-0 mb-3"
                          >
                            <div>
                              <strong>Total amount</strong>
                              <strong>
                                <p class="mb-0">(including VAT)</p>
                              </strong>
                            </div>
                            <span
                              ><strong>R {{ cartTotal() }}</strong></span
                            >
                          </div>
                        </div>

                        <button
                          type="button"
                          class="btn bg-6 btn-lg btn-block"
                          v-on:click="checkOut"
                        >
                          Complete Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import bootstrap javascript
import 'bootstrap';
import axios from 'axios';

const baseURL = process.env.VUE_APP_BACKEND_URL;
document.title = 'Checkout | VTIX';
export default {
  data() {
    return {
      cart: {
        items: [],
      },
      customer: {
        id: '',
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        province: '',
        post_code: '',
      },
      cartItems: [],
      createAccount: true,
    };
  },

  mounted() {
    this.cart = this.$store.state.cart;
  },
  methods: {
    updateCart(index, quantity) {
      let item = this.cart.items[index];
      item.quantity = quantity;
      this.$store.commit('addToCart', this.cart);
    },

    removeFromCart(index) {
      this.cart.items.splice(index, 1);
      this.$store.commit('addToCart', this.cart);
    },

    emptyCart() {
      this.cart.items = [];
      this.$store.commit('addToCart', this.cart);
    },

    formatPrice(price) {
      return price.toFixed(2);
    },

    formatCurrency(price) {
      return 'R ' + price.toFixed(2);
    },

    ticketTotal(ticket) {
      return ticket.quantity * ticket.price;
    },

    checkOut() {
      let userData = new FormData();
      userData.append('username', this.customer.username);
      userData.append('password', this.customer.password);
      userData.append('first_name', this.customer.first_name);
      userData.append('last_name', this.customer.last_name);
      userData.append('email', this.customer.email);
      userData.append('phone', this.customer.phone);
      userData.append('address', this.customer.address);
      userData.append('city', this.customer.city);
      userData.append('province', this.customer.province);
      userData.append('post_code', this.customer.post_code);

      let cartItems = [];

      this.cart.items.forEach((item) => {
        cartItems.push({
          id: item.id,
          evennt: item.event,
          ticket_type: item.ticket_type,
          ticket_price: item.price,
        });
      });

      //this.$store.commit('addToCart', this.cart);
      //use axios to create a new customer if the customer does not exist, use the new customer or return the existing customer and use the customer to create a new order
      axios
        .post(baseURL + '/api/v1/orders/', {
          customer: 5,
          status: 'pending',
          total: this.cartTotal(),
        })
        .then((response) => {
          this.cart.items.forEach((element) => {
            axios
              .post(baseURL + '/api/v1/orders/items/', {
                ticket_order: response.data.id,
                customer: 5,
                ticket: element.id,
                quantity: element.quantity,
                total: element.price,
              })
              .then((response) => {
                //We need to get the order id from the cart
                axios.post(baseURL + '/api/v1/payments/', {
                  order: response.data.id,
                  payment_method: 'credit_card',
                  payment_amount: this.cartTotal(),
                  payment_status: 'pending',
                });
              });
          });
        });
      /*              .then((response) => {
              //use the order to create a new payment
              axios
                .post(baseURL + '/api/v1/payments', {
                  payment: {
                    order_id: response.data.id,
                    payment_method: 'credit_card',
                    payment_amount: this.cartTotal(),
                    payment_status: 'pending',
                  },
                })
                .then((response) => {

                  //use the payment to create a new ticket
                  axios
                    .post(baseURL + '/api/v1/tickets', {
                      ticket: {
                        payment_id: response.data.id,
                        ticket_name: 'General Admission',
                        ticket_price: 100.0,
                        ticket_quantity: 1,
                        ticket_total: 100.0,
                      },
                    })
                    .then((response) => {

                    });
                });
            }); */
    },
  },

  computed: {
    mapState() {
      return this.$store.state;
    },
    cartTotal() {
      return this.store.getterscartTotal;
    },

    cartItemCount() {
      let totalItems = 0;
      if (this.cart.items.length > 0) {
        this.cart.items.forEach((item) => {
          if (item.quantity > 0) {
            totalItems += item.quantity;
          }
        });
      }
      return totalItems;
    },
  },
};
</script>
