<template>
  <nav class="nav nav-borders">
    <a
      class="nav-link ms-0"
      :class="isActive == 'profile' ? 'active' : ''"
      href="#"
      @click="goToProfile"
      >Profile</a
    >
    <a
      class="nav-link"
      :class="isActive == 'orders' ? 'active' : ''"
      href="#"
      @click="goToOrders"
      >Orders</a
    >
    <a
      class="nav-link"
      :class="isActive == 'tickets' ? 'active' : ''"
      href="#"
      @click="goToTickets"
      >Tickets</a
    >
    <a
      class="nav-link"
      :class="isActive == 'security' ? 'active' : ''"
      href="#"
      @click="goToSecurity"
      >Security</a
    >
    <a
      class="nav-link"
      :class="isActive == 'notifications' ? 'active' : ''"
      href="#"
      @click="goTonotifications"
      >Notifications</a
    >
  </nav>
  <hr class="mt-0 mb-4" />
</template>

<script>
export default {
  name: 'ProfileNav',

  data() {
    return {
      isActive: '',
      activeprofileNavTab: 'profile',
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === 'Account') {
        this.isActive = 'profile';
      }
      if (to.name === 'Orders') {
        this.isActive = 'orders';
      }
      if (to.name === 'Tickets') {
        this.isActive = 'tickets';
      }
      if (to.name === 'Security') {
        this.isActive = 'security';
      }
      if (to.name === 'Notifications') {
        this.isActive = 'notifications';
      }
    },
  },

  methods: {
    goToProfile() {
      //this.isActive = 'profile';
      this.$router.push({ name: 'Account' });
    },
    goToTickets() {
      this.$router.push({ name: 'Tickets' });
    },
    goToOrders() {
      // this.isActive = 'orders';
      this.$router.push({ name: 'Orders' });
      console.log(this.isActive);
    },
    goToSecurity() {
      this.isActive = 'security';
    },
    goTonotifications() {
      this.$router.push({ name: 'AccountNotifications' });
      //this.activeprofileNavTab = 'notifications';
    },
  },
};
</script>
