<style scoped>
.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #4e4066;
  color: #fff;
}
</style>

<template>
  <!-- Modal -->

  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Notification</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-xl-12">
          <!-- Account details card-->
          <div class="card mb-4">
            <div class="card-header">Invite Details</div>
            <div class="card-body">
              <form>
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <p>
                    You have a new invite from
                    {{ inviteDetail.company_name }} to join their team. This
                    will give you functionality to verify and redeem tickets.
                    View invite to accept or decline.
                  </p>
                  <p>
                    Invitation date :
                    {{ formatEventDates(inviteDetail.date_invited) }}
                  </p>
                  <p>
                    Invitation Exp :
                    {{ formatEventDates(inviteDetail.exp_date) }}
                  </p>
                  <p>Invitation status : {{ inviteDetail.status }}</p>
                  <p v-if="inviteDetail.status !== 'pending'">
                    Date {{ inviteDetail.status }} :
                    {{ formatEventDates(inviteDetail.updated_at) }}
                  </p>
                </div>

                <!-- Save changes button-->
                <button
                  v-if="inviteDetail.status == 'pending'"
                  @click="inviteResponse('accepted')"
                  data-bs-dismiss="modal"
                  class="btn bg-6 ml-4"
                  type="button"
                >
                  Accept Invite
                </button>
                <button
                  v-if="inviteDetail.status == 'pending'"
                  @click="inviteResponse('declined')"
                  data-bs-dismiss="modal"
                  class="btn bg-5 mr-4 me-4"
                  type="button"
                >
                  Decline Invite
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import callAPI from '../axios-api';
import axios from 'axios';
import { Vue } from 'vue-class-component';
import CustomToast from './CustomToast.vue';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'InviteDetail',
  props: {
    inviteDetail: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
      currentUser: {
        user: {},
        profile: {},
      },
    };
  },
  components: {
    CustomToast,
  },
  methods: {
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },
    getUserProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;
      axios
        .get(baseURL + '/api/v1/account/current/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          this.currentUser.user = res.data.user;
          this.currentUser.profile = res.data.profile;
          this.$store.state.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.isLoading = false;
        });
    },
    formatEventDates(date) {
      const strToDate = new Date(date);

      const localStrDate = strToDate.toLocaleString('en-ZA', {
        dateStyle: 'full',
      });
      const localStrTime = strToDate.toLocaleString('en-ZA', {
        timeStyle: 'medium',
      });

      //const fromDateOnly = new Date(fromDateString);

      return localStrDate + ' ' + localStrTime;
    },
    inviteResponse(invRsp) {
      const token = localStorage.getItem('token');
      const invite_id = this.inviteDetail.id;
      const invite_token = this.inviteDetail.invite_token;
      const invite_response = invRsp;
      if (!this.$store.getters.isTokenExpired) {
        axios
          .put(
            baseURL + '/api/v1/account/invite/' + invite_token + '/',
            {
              status: invRsp,
            },
            {
              headers: {
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === 'accepted') {
                this.updateUser();
              } else {
                this.customToast(
                  'success',
                  'Success',
                  'You have declined the invite from ' +
                    this.inviteDetail.company_name +
                    '.The company has been notified.'
                );
              }

              //this.getUserInvites();
            } else {
              this.customToast(
                'error',
                'Error',
                'Something went wrong, please refresh the page and try again.'
              );
            }
          });
      } else {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
          .then((response) => {
            this.inviteResponse(invRsp);
          })
          .catch((err) => {
            return err;
          });
      }
    },

    updateUser() {
      this.currentUser.profile.profile_type = 'rep';
      this.currentUser.profile.company = this.inviteDetail.company;
      console.log(this.currentUser);
      if (!this.$store.getters.isTokenExpired) {
        callAPI
          .put(
            '/api/v1/account/current/',
            {
              user: this.currentUser.user,
              profile: this.currentUser.profile,
            },
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              console.log(response);
              this.$emit('update-invite');
              this.customToast(
                'Success',
                'You have accepted the invite from ' +
                  this.inviteDetail.company_name +
                  '.You can now verify and redeem tickets.',
                true
              );
            } else {
              console.log(response);
              this.$emit('update-invite');
              this.customToast(
                'Error',
                'Something went wrong, please refresh the page and try again.' +
                  error.response.data.detail,
                true
              );
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store
                .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
                .then((response) => {
                  this.updateUser();
                })
                .catch((err) => {
                  this.customToast(
                    'Error',
                    'Something went wrong, please refresh the page and try again.' +
                      error.response.data.detail,
                    true
                  );
                });
            } else {
              this.customToast(
                'Error',
                'Something went wrong, please refresh the page and try again.' +
                  error.response.data.detail,
                true
              );
            }
          });
      } else {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
          .then((response) => {
            this.updateUser();
          })
          .catch((err) => {
            return err;
          });
      }
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>
