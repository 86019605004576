
import { Vue } from 'vue-class-component';

export default class Login extends Vue {
  [x: string]: any;
  name = 'Login';
  username: string = '';
  password: string = '';
  confirmPassword: string = '';
  isLogin: boolean = true;
  validForm: boolean = false;
  error: Array<string> = [];
  success: string = '';
  baseURL: string = process.env.VUE_APP_BACKEND_URL;
  currentUser: any = null;

  switchLogin() {
    this.$router.push('/login/');
  }
  switchReset() {
    this.$router.push('/password-reset/');
  }
  isValidForm() {
    if (this.password === this.confirmPassword) {
      this.validForm = true;
      return true;
    }
    if (this.password !== '' && this.confirmPassword !== '') {
      this.validForm = true;
      return false;
    }
  }

  doPwdReset() {
    if (this.isValidForm()) {
      //Get first three url parameters
      const userId = this.$route.query.user_id;
      const timeStamp = this.$route.query.timestamp;
      const signature = this.$route.query.signature;
      this.$store.state.isLoading = true;
      this.$store
        .dispatch('confirmReset', {
          password: this.password,
          user_id: userId,
          timestamp: timeStamp,
          signature: signature,
        })
        .then(() => {
          this.success =
            'Password reset successful. You will be redirected to the login page.';
          //set timeout to redirect to login page
          setTimeout(() => {
            this.$router.push('/login/');
          }, 3000);
          this.$store.state.isLoading = false;
        })
        .catch((err: { response: { data: { password: Array<string> } } }) => {
          this.$store.state.isLoading = false;
          this.error.push(err.response.data.password[0]);
        });
    } else {
      this.error.push('Unable to reset user password. Please contact support.');
      this.$store.state.isLoading = false;
    }
  }
}
