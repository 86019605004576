<style scoped>
body {
  margin-top: 20px;
  background-color: #f7f7ff;
}
.radius-10 {
  border-radius: 10px !important;
}

.border-info {
  border-left: 5px solid #0dcaf0 !important;
}
.border-danger {
  border-left: 5px solid #fd3550 !important;
}
.border-success {
  border-left: 5px solid #15ca20 !important;
}
.border-warning {
  border-left: 5px solid #ffc107 !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.bg-gradient-scooter {
  background: #17ead9;
  background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
  background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}
.widgets-icons-2 {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  font-size: 27px;
  border-radius: 10px;
}
.rounded-circle {
  border-radius: 50% !important;
}
.text-white {
  color: #fff !important;
}
.ms-auto {
  margin-left: auto !important;
}
.bg-gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.bg-gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-blooker {
  background: #ffdf40;
  background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
  background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #6f5b92;
  padding-left: 10px;
  margin-bottom: 30px;
}
.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>
<template>
  <section style="background-color: #eee">
    <div class="container py-5">
      <!--<ProfileNav />-->
      <CustomToast
        :toastInfo="{
          header: toastInfo.header,
          message: toastInfo.message,
          smallText: toastInfo.smallText,
          show: toastInfo.show,
        }"
      />
      <ProfileNav />

      <div class="row">
        <div class="col-md-6">
          <h1>Ticket Scanner</h1>
        </div>
        <div class="col-md-6 text-md-end">
          <a href="#!" class="text-body" @click="goBack">
            <i class="fas fa-long-arrow-alt-left me-2"></i>Back To Dashboard
          </a>
        </div>
      </div>
      <button
        type="button"
        class="btn bg-6 me-4"
        data-bs-toggle="modal"
        data-bs-target="#scanner"
        id="toggleButton"
        @click="toggleQRCodeStream('redeem')"
      >
        Redeem Ticket
      </button>
      <button
        type="button"
        class="btn bg-6"
        data-bs-toggle="modal"
        data-bs-target="#scanner"
        id="toggleButton"
        @click="toggleQRCodeStream('verify')"
      >
        Verify Ticket
      </button>
      <div class="container">
        <div
          id="scanner"
          class="modal fade"
          tabindex="-1"
          aria-labelledby="qrCodeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Redeem Ticket
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="toggleQRCodeStream"
                ></button>
              </div>
              <div class="modal-body">
                <div class="col-xl-12">
                  <!-- Account details card-->
                  <div class="card mb-4">
                    <div class="card-header">
                      Scan QR Code or Tap To Redeem Ticket
                    </div>
                    <div class="card-body">
                      <div>
                        <div id="qrcodeContainer">
                          <!-- The QR Code Stream will be added here when the button is clicked -->
                          <qrcode-stream
                            v-if="showQRCode"
                            @detect="onDetect"
                            :track="this.paintBoundingBox"
                            @error="logErrors"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import CustomToast from '@/components/CustomToast.vue';
import ProfileNav from '@/components/ProfileNav.vue';
import DashboardNav from '@/components/DashboardNav.vue';

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'TicketScanner',
  data() {
    const options = [
      { text: 'nothing (default)', value: undefined },
      { text: 'outline', value: this.paintOutline },
      { text: 'centered text', value: this.paintCenterText },
      { text: 'bounding box', value: this.paintBoundingBox },
    ];

    const selected = options[3];

    return {
      companyProfile: null,
      showQRCode: false,
      selected,
      options,
      actionToPerform: 'verify',
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: '',
      },
    };
  },
  components: {
    DashboardNav,
    ProfileNav,
    CustomToast,
    QrcodeStream,
  },
  methods: {
    toggleQRCodeStream(action) {
      this.showQRCode = !this.showQRCode;
      this.actionToPerform = action;
    },
    showToast(header, message, smallText, show) {
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      this.toastInfo.show = show;
    },
    verifyOrRedeemTicket(action, qrCodeData) {
      const authToken = this.$store.state.token;
      console.log(authToken);
      const refreshToken = this.$store.state.refres_token;

      if (!this.$store.getters.isTokenExpired) {
        console.log(qrCodeData);
        axios
          .post(
            baseURL + '/api/v1/digital/' + action + '/',
            {
              token: qrCodeData[0].rawValue,
            },
            {
              headers: {
                Authorization: `JWT ${authToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.showToast('Success', response.data.detail, '', true);
            } else {
              console.log(response.data);
              this.showToast('Error', response.data.detail, '', true);
            }
          })
          .catch((error) => {
            console.log(error);
            this.showToast('Error', error, '', true);
          });
      } else {
        this.$store.dispatch('refreshToken', refreshToken).then(() => {
          this.onDetect(detectedCodes);
        });
      }

      const openModalButton = document.querySelector(
        '[data-bs-toggle="modal"]'
      );
      if (openModalButton) {
        openModalButton.click();
        this.toastInfo.show = false;
      }
    },
    onDetect(detectedCodes) {
      if (!this.$store.getters.isTokenExpired) {
        this.verifyOrRedeemTicket(this.actionToPerform, detectedCodes);
      } else {
        this.$store.dispatch('refreshToken', refreshToken).then(() => {
          this.onDetect(detectedCodes);
        });
      }
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = '#b65c32';
        ctx.strokeRect(x, y, width, height);
      }
    },
    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode;

        const centerX = boundingBox.x + boundingBox.width / 2;
        const centerY = boundingBox.y + boundingBox.height / 2;

        const fontSize = Math.max(
          12,
          (50 * boundingBox.width) / ctx.canvas.width
        );
        console.log(boundingBox.width, ctx.canvas.width);

        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textAlign = 'center';

        ctx.lineWidth = 3;
        ctx.strokeStyle = '#35495e';
        ctx.strokeText(detectedCode.rawValue, centerX, centerY);

        ctx.fillStyle = '#5cb984';
        ctx.fillText(rawValue, centerX, centerY);
      }
    },
  },

  mounted() {},
};
</script>
