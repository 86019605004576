<style>
body {
  margin-top: 20px;
}

[class*='noty_theme__unify--v1'] {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1.57143rem;
}

.noty_theme__unify--v1--dark {
  background-color: #2e3c56;
}

.noty_theme__unify--v1--light {
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

.noty_type__success.noty_theme__unify--v1 {
  background-color: #1cc9e4;
}

.noty_type__info.noty_theme__unify--v1 {
  background-color: #1d75e5;
}

.noty_type__error.noty_theme__unify--v1 {
  background-color: #e62154;
}

.noty_type__warning.noty_theme__unify--v1 {
  background-color: #e6a821;
}

.noty_body {
  font-weight: 400;
  font-size: 1rem;
  color: #fff;
}

[class*='noty_theme__unify--v1'] .noty_body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.noty_theme__unify--v1--light .noty_body {
  color: #41464b;
}

.noty_body__icon {
  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.noty_body__icon::before {
  display: block;
}

.noty_body__icon > i {
  position: relative;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}

.noty_theme__unify--v1 .noty_body__icon {
  background-color: rgba(245, 249, 249, 0.2);
}

.noty_theme__unify--v1--dark .noty_body__icon {
  background-color: rgba(245, 249, 249, 0.1);
}

.noty_theme__unify--v1--dark.noty_type__success .noty_body__icon {
  color: #1cc9e4;
}

.noty_theme__unify--v1--dark.noty_type__info .noty_body__icon {
  color: #1d75e5;
}

.noty_theme__unify--v1--dark.noty_type__error .noty_body__icon {
  color: #e62154;
}

.noty_theme__unify--v1--dark.noty_type__warning .noty_body__icon {
  color: #e6a821;
}

.noty_theme__unify--v1--light.noty_type__success .noty_body__icon {
  background-color: rgba(28, 201, 228, 0.15);
  color: #1cc9e4;
}

.noty_theme__unify--v1--light.noty_type__info .noty_body__icon {
  background-color: rgba(29, 117, 229, 0.15);
  color: #1d75e5;
}

.noty_theme__unify--v1--light.noty_type__error .noty_body__icon {
  background-color: rgba(230, 33, 84, 0.15);
  color: #e62154;
}

.noty_theme__unify--v1--light.noty_type__warning .noty_body__icon {
  background-color: rgba(230, 168, 33, 0.15);
  color: #e6a821;
}

[class*='noty_theme__unify--v1'] .noty_close_button {
  top: 14px;
  right: 14px;
  width: 0.85714rem;
  height: 0.85714rem;
  line-height: 0.85714rem;
  background-color: transparent;
  font-weight: 300;
  font-size: 1.71429rem;
  color: #fff;
  border-radius: 0;
}

.noty_theme__unify--v1--light .noty_close_button {
  color: #cad6d6;
}

.noty_progressbar {
  height: 0.5rem !important;
}

.noty_theme__unify--v1 .noty_progressbar {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.g-mb-25 {
  margin-bottom: 1.78571rem !important;
}
h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #fa976c;
  padding-left: 10px;
  margin-bottom: 30px;
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}

.categories ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  text-align: center;
}

.categories ul li {
  display: inline-block;
  padding: 0;
  line-height: 24px;
  background: transparent;
  margin: 0;
  margin-left: 5px;
  margin-bottom: 5px;
}

.categories ul li a {
  display: block;
  font-size: 12px;
  font-weight: 300;
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid transparent;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.categories ul li a,
.categories ul li a:active,
.categories ul li a:hover {
  line-height: 24px;
  background: #fff;
  color: #000000;
  text-decoration: none;
}

.categories ul li a:hover {
  color: #fff;
  background: #b65c32;
}

.categories ul li.active a {
  color: #fff;
  background: #4e4066;
}
</style>
<template>
  <section style="background-color: #eee">
    <div class="container py-5">
      <!-- Account page navigation-->
      <ProfileNav />
      <h1>Notifications</h1>
      <div class="container">
        <section id="portfolio" class="gray-bg padding-top-bottom">
          <div class="container bootstrap snippets bootdey">
            <!--==== Portfolio Filters ====-->
            <div class="categories">
              <ul>
                <li
                  @click="setActiveFilter('pending')"
                  :class="isActive == 'pending' ? 'active' : ''"
                >
                  <a href="#" data-filter="*">Pending</a>
                </li>
                <li
                  @click="setActiveFilter('read')"
                  :class="isActive == 'read' ? 'active' : ''"
                >
                  <a href="#" data-filter=".web-design">Read</a>
                </li>
                <li
                  @click="setActiveFilter('accepted')"
                  :class="isActive == 'accepted' ? 'active' : ''"
                >
                  <a href="#" data-filter=".apps">Accepted</a>
                </li>
                <li
                  @click="setActiveFilter('rejected')"
                  :class="isActive == 'rejected' ? 'active' : ''"
                >
                  <a href="#" data-filter=".apps">Declined</a>
                </li>
                <li
                  @click="setActiveFilter('expired')"
                  :class="isActive == 'expired' ? 'active' : ''"
                >
                  <a href="#" data-filter=".psd">Expired</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div
          class="row g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md g-mb-30"
        >
          <!-- Success -->
          <div v-for="invite in userInvites" :key="invite.id" class="col-md-6">
            <div class="noty_bar bg-2 noty_theme__unify--v1 g-mb-25">
              <div class="noty_body">
                <div class="g-mr-20">
                  <div class="noty_body__icon">
                    <i class="hs-admin-check"></i>
                  </div>
                </div>
                <div>
                  You have a new invite from {{ invite.company_name }} to join
                  their team. This will give you functionality to verify and
                  redeem tickets. View invite to accept or decline.
                </div>
              </div>
              <button
                @click="getselectedInvite(invite.id)"
                data-bs-toggle="modal"
                data-bs-target="#invite-detail"
                type="button"
                class="btn bg-6 ms-1 mt-2"
              >
                View Invite
              </button>
              <p class="text-white text-end align-text-bottom">
                {{ formatEventDates(invite.date_invited) }}
              </p>
            </div>
          </div>
          <!-- End Success -->
        </div>
        <PaginationComp :paginationData="paginationData" />
      </div>
    </div>
  </section>

  <div
    class="modal fade"
    id="invite-detail"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <InviteDetail
      :inviteDetail="selectedInvite"
      @update-invite="getUserInvites(isActive)"
    />
  </div>
  <CustomToast :toastInfo="toastInfo" />
</template>
<script>
import InviteDetail from '@/components/InviteDetail.vue';
import ProfileNav from '@/components/ProfileNav.vue';
import CustomToast from '@/components/CustomToast.vue';
import PaginationComp from '@/components/PaginationComp.vue';
import axios from 'axios';
const baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'AccountNotifications',
  components: {
    InviteDetail,
    ProfileNav,
    CustomToast,
    PaginationComp,
  },

  data() {
    return {
      userInvites: [],
      selectedInvite: {
        id: '',
        invite_token: '',
        first_name: '',
        last_name: '',
        company_name: '',
        company: '',
        date_invited: '',
        exp_date: '',
        status: '',
      },
      isActive: 'pending',
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
      paginationData: {
        djangoResults: {
          count: 0,
          next: null,
          previous: null,
          results: [],
        },
        currentPage: 0,
        recordsPerpage: 0,
        totalRecords: 0,
        apiEndPoint: '/api/v1/digital/tickets/',
        records: [],
        pageSize: 4,
        maxTabs: 3,
        baseURL: process.env.VUE_APP_BACKEND_URL,
      },
    };
  },

  methods: {
    getUserInvites(filter) {
      const token = this.$store.state.token;

      if (!this.$store.getters.isTokenExpired) {
        this.$store.state.isLoading = true;
        axios
          .get(baseURL + '/api/v1/account/invites?search=' + filter + '', {
            headers: {
              Authorization: `JWT ${token}`,
            },
          })
          .then((response) => {
            this.userInvites = response.data.results;
            this.paginationData.djangoResults = response.data;
            this.paginationData.recordsPerpage = response.data.results.length;
            for (
              let i = 0;
              i <
              Math.ceil(
                this.paginationData.djangoResults.count /
                  this.paginationData.djangoResults.results.length
              );
              i++
            ) {
              this.paginationData.records.push(i);
              console.log(i);
            }
            this.$store.state.isLoading = false;
          })
          .catch((error) => {
            this.$store.state.isLoading = false;
            if (error.response.status === 401) {
              this.$store
                .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
                .then((response) => {
                  if (response.status === 200) {
                    this.getUserInvites(this.isActive);
                  } else {
                    this.showToast(
                      'error',
                      response.message,
                      'There was an error retrieving your notification. Please check your internet connection and try again. If the problem persists, please contact support.'
                    );
                  }
                })
                .catch((error) => {
                  this.toastInfo = {
                    header: 'Error',
                    message:
                      'There was an error retrieving your notification. Please check your internet connection and try again. If the problem persists, please contact support.',
                    smallText: error.message,
                    show: true,
                  };
                });
              this.$router.push({ name: 'Login' });
            }
            this.toastInfo = {
              header: 'Error',
              message:
                'There was an error retrieving your notification. Please check your internet connection and try again. If the problem persists, please contact support.',
              smallText: error.message,
              show: true,
            };
          });
      } else {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
          .then((response) => {
            if (response.status === 200) {
              this.getUserInvites(this.isActive);
            } else {
              this.toastInfo = {
                header: 'Error',
                message:
                  'There was an error retrieving your notification. Please check your internet connection and try again. If the problem persists, please contact support.',
                smallText: error.message,
                show: true,
              };
            }
          });
      }
    },
    getselectedInvite(id) {
      this.selectedInvite = this.userInvites.find((invite) => invite.id === id);
    },
    setActiveFilter(filter) {
      this.$store.state.isLoading = true;
      this.isActive = filter;
      this.getUserInvites(filter);
      this.$store.state.isLoading = false;
    },
    formatEventDates(date) {
      const strToDate = new Date(date);

      const localStrDate = strToDate.toLocaleString('en-ZA', {
        dateStyle: 'full',
      });
      const localStrTime = strToDate.toLocaleString('en-ZA', {
        timeStyle: 'medium',
      });

      //const fromDateOnly = new Date(fromDateString);

      return localStrDate + ' ' + localStrTime;
    },
    showToast(header, message, smallText, show) {
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      this.toastInfo.show = show;
    },
  },
  mounted() {
    this.getUserInvites(this.isActive);
  },
  watch: {
    isActive: (val) => {
      //this.getUserInvites(val);
    },
  },
};
</script>
