<template>
  <!--<OrderHistory v-if="currentUser" :orders="orders" />-->
  <section style="background-color: #eee">
    <div class="container py-5">
      <ProfileNav />
      <OrderHistory :orders="orders" />
      <div class="row">
        <div class="col-12">
          <div class="text-right">
            <ul class="pagination pagination-split mt-0 float-right">
              <li class="page-item">
                <button
                  class="page-link"
                  :disabled="currentPage === 0"
                  @click="goToFirstPage"
                  aria-label="First"
                >
                  <span aria-hidden="true">««</span>
                  <span class="sr-only">First</span>
                </button>
              </li>
              <li class="page-item">
                <button
                  :disabled="currentPage === 0"
                  class="page-link"
                  @click="goToPreviousPage"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">«</span>
                  <span class="sr-only">Previous</span>
                </button>
              </li>
              <li
                class="page-item"
                v-for="(page, key) in this.orderPages"
                :class="{ active: currentPage === page }"
                :key="key"
                :if="page >= currentPage - 2 && page <= currentPage + 2"
              >
                <button class="page-link" @click="goToPage(page)">
                  {{ page + 1 }}
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  :disabled="currentPage === this.orderPages.length - 1"
                  v-if="
                    currentPage + this.ordersPerPage <
                    this.orderPages.length - 1
                  "
                  @click="goToMorePages"
                  aria-label="Next"
                >
                  <span aria-hidden="true">...</span>
                  <span class="sr-only">Next</span>
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  :disabled="currentPage === this.orderPages.length - 1"
                  @click="goToNextPage"
                  aria-label="Next"
                >
                  <span aria-hidden="true">»</span>
                  <span class="sr-only">Next</span>
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  :disabled="currentPage === this.orderPages.length - 1"
                  @click="goToLastPage"
                  aria-label="Last"
                >
                  <span aria-hidden="true">»»</span>
                  <span class="sr-only">Last</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import OrderHistory from '@/components/OrderHistory.vue';
import ProfileNav from '@/components/ProfileNav.vue';
import CustomToast from '@/components/CustomToast.vue';
import callAPI from '../axios-api';
import axios from 'axios';

export default {
  name: 'Orders',

  components: {
    OrderHistory,
    ProfileNav,
    CustomToast,
  },

  data() {
    return {
      orders: {},
      currentPage: 0,
      ordersPerPage: 0,
      orderPages: [],
      totalOrders: 0,
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
    };
  },
  methods: {
    getOrders() {
      this.$store.state.isLoading = true;
      callAPI
        .get(`/api/v1/orders/${this.$store.state.user_id}/`, {
          headers: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        })
        .then((response) => {
          this.orders = response.data;
          for (
            let i = 0;
            i < Math.ceil(this.orders.count / this.orders.results.length);
            i++
          ) {
            this.orderPages.push(i);
          }
          this.ordersPerPage = this.orders.results.length;
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          if (error.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
              .then((res) => {
                this.getOrders();
              })
              .catch((err) => {
                this.$store.state.isLoading = false;
                this.toastInfo = {
                  header: 'Error',
                  message:
                    'There was an error retrieving your orders. Please check your internet connection and try again. If the problem persists, please contact support.',
                  smallText: err.message,
                  show: true,
                };
              });
          } else {
            this.toastInfo = {
              header: 'Error',
              message:
                'There was an error retrieving your orders. Please check your internet connection and try again. If the problem persists, please contact support.',
              smallText: error.message,
              show: true,
            };
          }
        });
    },
    setRouteParam() {
      selctedPage = 0;
      this.$router.push({
        name: 'Orders',
        params: { page: selctedPage },
      });
    },
    goToFirstPage() {
      this.currentPage = 0;
      this.goToPage(this.currentPage);
    },
    async goToPage(page) {
      this.currentPage = page;
      this.$store.state.isLoading = true;
      const url = `/api/v1/orders/${this.$store.state.user_id}/?page=${
        page + 1
      }`; // replace with your actual API endpoint
      try {
        const response = await callAPI.get(url, {
          headers: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        });
        if (response.status === 200) {
          this.orders = response.data;
          this.currentPage = page;

          this.$router.push({
            name: this.$route.name,
            query: { page: (this.currentPage + 1).toString() },
          });
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store
            .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
            .then((res) => {
              this.goToPage(page);
            });
        }
      } finally {
        this.$store.state.isLoading = false;
      }
    },
    async goToNextPage() {
      this.$store.state.isLoading = true;
      if (this.orders.next) {
        const response = await axios.get(this.orders.next, {
          headers: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        });
        try {
          this.orders = response.data;
          this.currentPage++;
          this.$store.state.isLoading = false;
        } catch (error) {
          this.$store
            .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
            .then((res) => {
              this.goToNextPage();
            });
        }
        this.orders = response.data;
        this.$store.state.isLoading = false;
      }
    },
    async goToPreviousPage() {
      this.$store.state.isLoading = true;
      if (this.orders.previous) {
        console.log(this.orders.previous);
        const response = await axios.get(this.orders.previous, {
          headers: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        });
        this.orders = response.data;
        this.$store.state.isLoading = false;
        this.currentPage--;
      }
    },
    goToLastPage() {
      this.currentPage =
        Math.ceil(this.orders.count / this.orders.results.length) - 1;
      this.goToPage(
        Math.ceil(this.orders.count / this.orders.results.length) - 1
      );
    },
    goToMorePages() {
      if (this.currentPage + this.ordersPerPage <= this.orderPages.length) {
        this.goToPage(this.currentPage + ordersPerPage);
      }
    },
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
  },

  created() {
    document.title = 'My Orders | JusTix';
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  watch: {
    currentUser() {
      this.$store.dispatch('getOrders').then((response) => {
        this.orders = response.data;
      });
    },
  },

  mounted() {
    this.getOrders();
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page - 1;
    } else {
      this.currentPage = 0;
      this.$route.query.page = 1;
    }
  },

  beforeDestroy() {
    callAPI.get('/api/v1/orders/').then((response) => {
      this.orders = response.data;
    });
  },
};
</script>
