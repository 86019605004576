<style>
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 0;
  border-radius: 0.25rem;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 2rem;
  cursor: pointer;
  margin: 0;
  position: relative;
  box-shadow: 0px 0px 1px #474747;
  border-radius: 50%;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 1rem;
  height: 2px;
  background-color: #212121;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type='number'] {
  max-width: 3rem;
  text-align: center;
}

.multiline {
  white-space: pre-wrap;
}

.fit-image {
  width: 100%;
  object-fit: cover;
  /*height: 300px;  only if you want fixed height */
}
</style>
<template>
  <div class="event">
    <h1></h1>
  </div>
  <div class="row">
    <div class="col-md-12 mb-12">
      <div class="mb-12">
        <div class="container py-5 h-100">
          <div
            class="row d-flex justify-content-center align-items-center h-100"
          >
            <div class="col-12">
              <div
                class="card card-registration card-registration-2"
                style="border-radius: 15px"
              >
                <div class="card-body p-0">
                  <div class="row g-0">
                    <div class="col-lg-8">
                      <div class="p-1">
                        <div class="preview">
                          <div class="preview-pic tab-content">
                            <div class="tab-pane active c" id="pic-1">
                              <img
                                class="col-md-12 fit-image"
                                v-bind:src="venue.image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="p-5">
                        <h1 class="product-title">{{ venue.name }}</h1>
                        <hr class="my-4" />

                        <hr class="my-2" />
                        <div class="list-group-item">
                          <p class="product-description">
                            {{ venue.short_description }}
                          </p>
                          <p class="">
                            <strong> City : {{ venue.city }} </strong>
                          </p>
                          <p class="">
                            <strong>Province : {{ venue.province }}</strong>
                          </p>
                          <p class="">
                            <strong>Address : {{ venue.address }} </strong>
                          </p>

                          <p class="">
                            <strong>Contact : {{ venue.contact }} </strong>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <p>Capacity :</p>
                        <p>Category : {{ venue.category_name }}</p>
                        <div class="b-4 list-group-item"><p></p></div>
                        <div class="container list-group-item">
                          <button class="like btn btn-default" type="button">
                            <span class="fa fa-heart"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { mapState } from 'vuex';
import CustomToast from '@/components/CustomToast.vue';
//import { ToastPlugin } from 'bootstrap-vue';

export default {
  name: 'EventView',

  data() {
    return {
      venue: {},
      baseURL: process.env.VUE_APP_BACKEND_URL,
    };
  },
  mounted() {
    this.getVenuetDetails();
  },
  methods: {
    getVenuetDetails() {
      const category_slug = this.$route.params.slug_category;
      const venue_slug = this.$route.params.slug_venue;
      const baseURL = process.env.VUE_APP_BACKEND_URL;

      axios
        .get(baseURL + `/api/v1/venues/${category_slug}/${venue_slug}`)
        .then((response) => {
          this.venue = response.data;
          document.title = this.venue.name + ' | VTIX';
        });
    },

    cartTotal() {
      let total = 0;
      this.cart.items.forEach((item) => {
        total += item.quantity * item.price;
      });
      return total;
    },
  },
  components: {
    CustomToast,
  },
};
</script>
