
import { Vue } from 'vue-class-component';
import axios from 'axios';

export default class HomeView extends Vue {
  [x: string]: any;
  name: 'HomeView' = 'HomeView';
  latestEvents: any;

  getLatestEvents() {
    const baseURL = process.env.VUE_APP_BACKEND_URL;
    document.title = 'Digital Tickets - VTIX';

    axios
      .get(baseURL + '/api/v1/events/latest?_sort=id&_order=desc')
      //add error handling
      .then((response) => {
        this.latestEvents = response.data;
      })
      .catch((error) => {
        this.latestEvents = '';
      });
  }
  mounted() {
    this.getLatestEvents();
  }

  data() {
    return {
      latestEvents: [],
    };
  }
}
