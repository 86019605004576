<template>
  <!-- Modal -->

  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Redeem Ticket</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-xl-12">
          <!-- Account details card-->
          <div class="card mb-4">
            <div class="card-header">Scan QR Code or Tap To Redeem Ticket</div>
            <div class="card-body">
              <img
                :src="qr_code_url"
                alt="QR Code Image"
                style="height: 300px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import callAPI from '../axios-api';
import { Vue } from 'vue-class-component';
import CustomToast from './CustomToast.vue';
export default {
  name: 'CustomerQRCODE',
  props: {
    qr_code_url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
    };
  },
  components: {
    CustomToast,
  },
};
</script>
