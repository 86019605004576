<style scoped>
body {
  background-color: #f3f6f8;
  margin-top: 20px;
}
.thumb-lg {
  height: 200px;
  width: 200px;
}
.profile-user-box {
  position: relative;
  border-radius: 5px;
}
.bg-custom {
  background-color: #02c0ce !important;
}
.profile-user-box {
  position: relative;
  border-radius: 5px;
}

.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}
.inbox-widget .inbox-item img {
  width: 40px;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid #f3f6f8;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}

.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}

.inbox-widget .inbox-item img {
  width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
  color: #313a46;
  display: block;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-text {
  color: #98a6ad;
  display: block;
  font-size: 14px;
  margin: 0;
}

.inbox-widget .inbox-item .inbox-item-date {
  color: #98a6ad;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 12px;
}

.comment-list .comment-box-item {
  position: relative;
}

.comment-list .comment-box-item .commnet-item-date {
  color: #98a6ad;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}

.comment-list .comment-box-item .commnet-item-msg {
  color: #313a46;
  display: block;
  margin: 10px 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.comment-list .comment-box-item .commnet-item-user {
  color: #98a6ad;
  display: block;
  font-size: 14px;
  margin: 0;
}

.comment-list a + a {
  margin-top: 15px;
  display: block;
}

.ribbon-box .ribbon-primary {
  background: #2d7bf4;
}

.ribbon-box .ribbon {
  position: relative;
  float: left;
  clear: both;
  padding: 5px 12px 5px 12px;
  margin-left: -30px;
  margin-bottom: 15px;
  font-family: Rubik, sans-serif;
  -webkit-box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  -o-box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  box-shadow: 2px 5px 10px rgba(49, 58, 70, 0.15);
  color: #fff;
  font-size: 13px;
}
.text-custom {
  color: #02c0ce !important;
}

.badge-custom {
  background: #02c0ce;
  color: #fff;
}
.badge {
  font-family: Rubik, sans-serif;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  padding: 0.35em 0.5em;
  font-weight: 500;
}
.text-muted {
  color: #98a6ad !important;
}

.font-13 {
  font-size: 13px !important;
}

.hide {
  display: none;

  /* Company Logo*/
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #6f5b92;
  color: #fff;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #fa976c;
  padding-left: 10px;
  margin-bottom: 30px;
}
</style>

<template>
  <section style="background-color: #eee">
    <div class="container py-5">
      <ProfileNav />
      <h1>Company Dashboard</h1>
      <div :class="{ hide: profileType !== 'merchant' }" class="container">
        <div class="row">
          <div class="col-sm-12">
            <!-- meta -->
            <div class="profile-user-box card-box bg-7">
              <div class="row">
                <div class="col-sm-4">
                  <span class="float-left mr-3">
                    <div class="profile-pic-wrapper">
                      <div class="pic-holder">
                        <!-- uploaded pic shown here -->
                        <img
                          id="profilePic"
                          class="rounded-circle thumb-lg"
                          :src="companyProfile.logo"
                        />
                      </div>
                    </div>
                  </span>
                </div>
                <div class="col-sm-6">
                  <div class="text-right">
                    <div class="media-body text-white">
                      <i class="font-13 text-light">
                        Company ID: {{ companyProfile.id }}
                      </i>
                      <h4 class="mt-1 mb-1 font-18">
                        {{ companyProfile.name }}
                      </h4>

                      <p class="text-light mb-0">
                        Email: {{ companyProfile.email }}
                      </p>
                      <p class="text-light mb-0">
                        Phone: {{ companyProfile.phone }}
                      </p>
                      <p class="text-light mb-0">
                        website:
                        {{ companyProfile.website }}
                      </p>

                      <p class="text-light mb-0">
                        Adress: {{ companyProfile.address }},
                        {{ companyProfile.suburb }}, {{ companyProfile.city }},
                        {{ companyProfile.province }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-align-center col-sm-4">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#company-profile"
                    type="button"
                    class="btn bg-6 ms-1"
                  >
                    <i class="mdi mdi-account-settings-variant mr-1"></i> Edit
                    Profile
                  </button>
                </div>
              </div>
            </div>
            <!--/ meta -->
          </div>
        </div>
        <!-- end row -->
        <div class="row">
          <div class="col-xl-4">
            <!-- Personal-Information -->
            <div class="card-box">
              <h4 class="header-title mt-0">Tools</h4>
              <div class="panel-body">
                <hr />
                <div class="col-md-6">
                  <!-- left -->
                  <p>
                    <a href="/account/dashboard/events/"
                      ><i class="fa fa-calendar"></i> Events</a
                    >
                  </p>
                  <p>
                    <a href="/account/dashboard/orders/"
                      ><i class="fa fa-credit-card-alt"></i> Orders</a
                    >
                  </p>
                  <p>
                    <a href="/account/dashboard/scanner/"
                      ><i class="fa fa-ticket"></i> Tickets</a
                    >
                  </p>
                  <p>
                    <a href="/account/dashboard/reports/"
                      ><i class="fa fa-bar-chart"></i> Reports</a
                    >
                  </p>
                  <p>
                    <a href="/account/dashboard/reps/"
                      ><i class="fa fa-users"></i> Manage Reps</a
                    >
                  </p>
                  <p>
                    <a href="/account/dashboard/settings/"
                      ><i class="fa fa-cogs"></i> Settings</a
                    >
                  </p>

                  <hr />
                </div>
              </div>
            </div>
            <!-- Personal-Information -->
            <div class="card-box ribbon-box">
              <div class="ribbon ribbon-primary">Top Reps</div>
              <div class="clearfix"></div>
              <div class="inbox-widget">
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar2.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Tomaslau</p>
                    <p class="inbox-item-text">
                      I've finished it! See you so...
                    </p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar3.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Stillnotdavid</p>
                    <p class="inbox-item-text">This theme is awesome!</p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar4.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Kurafire</p>
                    <p class="inbox-item-text">Nice to meet you</p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar5.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Shahedk</p>
                    <p class="inbox-item-text">Hey! there I'm available...</p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Adhamdannaway</p>
                    <p class="inbox-item-text">This theme is awesome!</p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar2.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Tomaslau</p>
                    <p class="inbox-item-text">
                      I've finished it! See you so...
                    </p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
                <a href="#">
                  <div class="inbox-item">
                    <div class="inbox-item-img">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar3.png"
                        class="rounded-circle"
                        alt=""
                      />
                    </div>
                    <p class="inbox-item-author">Stillnotdavid</p>
                    <p class="inbox-item-text">This theme is awesome!</p>
                    <p class="inbox-item-date">
                      <button
                        type="button"
                        class="btn btn-icon btn-sm waves-effect waves-light btn-success"
                      >
                        View Rep
                      </button>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="row">
              <div class="col-sm-4">
                <div class="card-box tilebox-one">
                  <i class="icon-layers float-right text-muted"></i>
                  <h6 class="text-muted text-uppercase mt-0">Orders</h6>
                  <h2 class="" data-plugin="counterup">1,587</h2>
                  <span class="badge badge-custom">+11% </span
                  ><span class="text-muted">From previous period</span>
                </div>
              </div>
              <!-- end col -->
              <div class="col-sm-4">
                <div class="card-box tilebox-one">
                  <i class="icon-paypal float-right text-muted"></i>
                  <h6 class="text-muted text-uppercase mt-0">Sales</h6>
                  <h2 class="">R<span data-plugin="counterup">46,782</span></h2>
                  <span class="badge badge-danger">-29% </span
                  ><span class="text-muted">From previous period</span>
                </div>
              </div>
              <!-- end col -->
              <div class="col-sm-4">
                <div class="card-box tilebox-one">
                  <i class="icon-rocket float-right text-muted"></i>
                  <h6 class="text-muted text-uppercase mt-0">Tickets Sold</h6>
                  <h2 class="" data-plugin="counterup">1,890</h2>
                  <span class="badge badge-custom">+89% </span
                  ><span class="text-muted">Last year</span>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
            <div class="card-box">
              <h4 class="header-title mt-0 mb-3">Experience</h4>
              <div class="">
                <div class="">
                  <h5 class="text-custom">Lead designer / Developer</h5>
                  <p class="mb-0">websitename.com</p>
                  <p><b>2010-2015</b></p>
                  <p class="text-muted font-13 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
                <hr />
                <div class="">
                  <h5 class="text-custom">Senior Graphic Designer</h5>
                  <p class="mb-0">coderthemes.com</p>
                  <p><b>2007-2009</b></p>
                  <p class="text-muted font-13 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
            </div>
            <div class="card-box">
              <h4 class="header-title mb-3">Event Summary</h4>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Event Name</th>
                      <th>Event Date</th>
                      <th>Tickets Sold</th>
                      <th>Status</th>
                      <th>Assign</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Adminox Admin</td>
                      <td>01/01/2015</td>
                      <td>07/05/2015</td>
                      <td>
                        <span class="label label-info">Work in Progress</span>
                      </td>
                      <td>Coderthemes</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Adminox Frontend</td>
                      <td>01/01/2015</td>
                      <td>07/05/2015</td>
                      <td><span class="label label-success">Pending</span></td>
                      <td>Coderthemes</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Adminox Admin</td>
                      <td>01/01/2015</td>
                      <td>07/05/2015</td>
                      <td><span class="label label-pink">Done</span></td>
                      <td>Coderthemes</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Adminox Frontend</td>
                      <td>01/01/2015</td>
                      <td>07/05/2015</td>
                      <td>
                        <span class="label label-purple">Work in Progress</span>
                      </td>
                      <td>Coderthemes</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Adminox Admin</td>
                      <td>01/01/2015</td>
                      <td>07/05/2015</td>
                      <td>
                        <span class="label label-warning">Coming soon</span>
                      </td>
                      <td>Coderthemes</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- container -->
      <!-- 404 if not merchant-->
      <Page404 :class="{ hide: profileType === 'merchant' }" />
    </div>
  </section>
  <div
    class="modal fade"
    id="company-profile"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <ConpanyProfile :company="companyProfile" />
  </div>
</template>
<script>
import ProfileNav from '@/components/ProfileNav.vue';
import DashboardNav from '@/components/DashboardNav.vue';
import Page404 from '@/components/404.vue';
import ConpanyProfile from '@/components/CompanyProfile.vue';
import axios from 'axios';
import callAPI from '../../axios-api.ts';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'MerchantDashboard',
  data() {
    return {
      islogin: true,
      profileType: '',
      currentUser: {},
      userprofile: {},
      companyProfile: {},
      propCurrentAccount: {
        user: {},
        profile: {},
      },

      activeprofileNavTab: 'profile',
      isLoading: false,
    };
  },
  created() {
    document.title = 'Merchant Dashboard | VTIX';
  },
  components: {
    DashboardNav,
    ProfileNav,
    Page404,
    ConpanyProfile,
  },
  mounted() {
    this.$store.state.isLoading = true;
    this.profileType = this.$store.getters.isProfiletype;
    if (!this.$store.state.isTokenExpired) {
      this.getCompanyProfile();
    } else {
      this.$store
        .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
        .then(() => {
          this.getCompanyProfile();
        });
    }
    this.$store.state.isLoading = false;
  },
  $route(to, from) {
    if (to.path === '/account/dashboard/reps') {
      this.mounted();
    }
  },
  methods: {
    doLogout() {
      this.$store.commit('clearToken');
      this.$store.commit('clearCart');
      this.$router.push('/');
    },

    async getCompanyProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;

      await axios
        .get(baseURL + '/api/v1/account/company/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          this.companyProfile = res.data.company;
          this.$store.commit('setMerchantSlug', res.data.company.slug);
          this.$store.state.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.isLoading = false;
        });
    },
  },
};
</script>
