<template>
  <!--<OrderHistory v-if="currentUser" :orders="orders" />-->
  <section style="background-color: #eee">
    <div class="container py-5">
      <ProfileNav />
      <h1>Tickets</h1>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="col-lg-8">
          <TicketBox :tickets="paginationData.djangoResults.results" />
          <!--<EventTicket :tickets="tickets" />-->
        </div>
        <PaginationComp :paginationData="paginationData" />
      </div>
    </div>
  </section>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import TicketBox from '@/components/TicketBox.vue';
import EventTicket from '@/components/EventTicket.vue';
import ProfileNav from '@/components/ProfileNav.vue';
import CustomerQRCODE from '@/components/CustomerQRCODE.vue';
import PaginationComp from '@/components/PaginationComp.vue';
import CustomToast from '@/components/CustomToast.vue';
import callAPI from '../axios-api';
export default {
  name: 'Tickets',
  data() {
    return {
      currentUser: null,
      tickets: [],
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
      paginationData: {
        djangoResults: {
          count: 0,
          next: null,
          previous: null,
          results: [],
        },
        currentPage: 0,
        recordsPerpage: 0,
        totalRecords: 0,
        apiEndPoint: '/api/v1/digital/tickets/',
        records: [],
        pageSize: 4,
        maxTabs: 3,
        baseURL: process.env.VUE_APP_BACKEND_URL,
      },
    };
  },

  components: {
    TicketBox,
    EventTicket,
    ProfileNav,
    CustomerQRCODE,
    CustomToast,
    PaginationComp,
  },
  methods: {
    getTickets() {
      this.$store.state.isLoading = true;
      callAPI
        .get(`/api/v1/digital/tickets/?page_size=4`)
        .then((response) => {
          this.paginationData.djangoResults = response.data;
          this.paginationData.recordsPerpage = response.data.results.length;
          for (
            let i = 0;
            i <
            Math.ceil(
              this.paginationData.djangoResults.count /
                this.paginationData.djangoResults.results.length
            );
            i++
          ) {
            this.paginationData.records.push(i);
            console.log(i);
          }
          console.log(this.paginationData);
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.isLoading = false;
          if (error.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((res) => {
                this.getTickets();
              })
              .catch((err) => {
                this.$store.state.isLoading = false;
                console.log(err);
                this.toastInfo = {
                  header: 'Error',
                  message:
                    'Could not retrieve tickets. Please check your internet connection and try again. Contact support if the problem persists.',
                  smallText: err.message,
                  show: true,
                };
              });
          } else {
            console.log(error);
            this.toastInfo = {
              header: 'Error',
              message:
                'Could not retrieve tickets. Please check your internet connection and try again. Contact support if the problem persists.',
              smallText: error.message,
              show: true,
            };
          }
        });
    },
  },
  mounted() {
    this.getTickets();
  },
  created() {
    document.title = 'My Tickets | JusTix';
  },
};
</script>

<style scoped></style>
