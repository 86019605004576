<style scoped>
h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #ffc541;
  padding-left: 10px;
  margin-bottom: 30px;
}

.hidden {
  visibility: hidden;
}
</style>
<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn hidden btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
  >
    Launch demo modal
  </button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <!-- <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <span class="event-icon"></span>
          </h4>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form id="add-event">
            <div class="modal-body">
              <h4>Add Event Detail</h4>
              <div class="form-group">
                <label>Event name</label>
                <input
                  type="text"
                  id="event-name"
                  class="form-control event-title"
                  name="ename"
                  v-model="selectedEvent.title"
                />
              </div>
              <div class="form-group">
                <label>Event Date</label>
                <input
                  type="datetime"
                  v-model="selectedEvent.start"
                  class="form-control"
                  name="edate"
                />
              </div>
              <div class="form-group event-body">
                <label>Event Description</label>
                <textarea
                  class="form-control"
                  v-bind="selectedEvent.description"
                  name="edesc"
                ></textarea>
              </div>
              <div class="form-group">
                <label>Event Color</label>
                <select class="form-control" name="ecolor">
                  <option value="fc-bg-default">fc-bg-default</option>
                  <option value="fc-bg-blue">fc-bg-blue</option>
                  <option value="fc-bg-lightgreen">fc-bg-lightgreen</option>
                  <option value="fc-bg-pinkred">fc-bg-pinkred</option>
                  <option value="fc-bg-deepskyblue">fc-bg-deepskyblue</option>
                </select>
              </div>
              <div class="form-group">
                <label>Event Icon</label>
                <select class="form-control" name="eicon">
                  <option value="circle">circle</option>
                  <option value="cog">cog</option>
                  <option value="group">group</option>
                  <option value="suitcase">suitcase</option>
                  <option value="calendar">calendar</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Save</button>
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> -->
    <EventEditor :event="selectedEvent" @clearData="clearSelectedEventData()" />
  </div>

  <section style="background-color: #eee">
    <div class="container py-5">
      <!--<ProfileNav />-->
      <ProfileNav />
      <div class="">
        <div class="row">
          <div class="col-md-6">
            <h1>Event Calendar</h1>
          </div>
          <div class="col-md-6 text-md-end">
            <a href="#!" class="text-body" @click="goBack">
              <i class="fas fa-long-arrow-alt-left me-2"></i>Back To Dashboard
            </a>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-0">
            <div ref="fullCalendar" id="calendar"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import flatpickr from 'flatpickr';
import axios from 'axios';
import { Calendar } from 'fullcalendar';
import interactionPlugin from '@fullcalendar/interaction'; // Import interactionPlugin
import dayGridPlugin from '@fullcalendar/daygrid'; // Import dayGridPlugin

import ProfileNav from '@/components/ProfileNav.vue';
import EventEditor from '@/components/EventEditor.vue';
const baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'Merchantevents',
  components: {
    ProfileNav,

    EventEditor,
  },
  data() {
    return {
      selectedEvent: {},
      allEvents: {},
      calendarEl: null,
      calendar: null,
      calendarView: 'dayGridMonth',
      calendarHeader: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      titleFormat: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getEvents() {
      if (!this.$store.state.isTokenExpired) {
        let result;
        await axios
          .get(
            baseURL +
              `/api/v1/merchant/${this.$store.getters.merchantSlug}/events/`
          )
          .then((response) => {
            this.allEvents = response.data;
          });
      } else {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
          .then(() => {
            this.getEvents();
          });
      }
    },
    eventRender(event, element) {
      if (event) {
        console.log(event);
        eventElement
          .find('div.fc-content')
          .prepend("<img src='" + event.imageurl + "' width='16' height='16'>");
      }
    },
    dateClick() {
      console.log('date clicked');

      const openModalButton = document.querySelector(
        '[data-bs-toggle="modal"]'
      );
      if (openModalButton) {
        openModalButton.click();
      }
    },
    resizeCalendar() {
      if (this.whichView() !== this.calendar.view.type) {
        this.calendar.destroy();
        this.loadCalendar(); // Re-initialize the calendar
        console.log('resize calendar');
        console.log(this.calendar.view.type);
      }
    },

    whichView() {
      if (window.innerWidth < 800) {
        this.calendarView = 'timeGridDay'; // You can adjust this view as needed
        this.calendarHeader = {
          left: 'prev,next',
          center: 'title',
          right: 'timeGridDay,timeGridWeek',
        };
        return 'dayGridDay'; // You can adjust this view as needed
      } else {
        this.calendarView = 'dayGridMonth';
        return 'dayGridMonth';
      }
    },

    eventClick(event, jsEvent, view) {
      this.selectedEvent = event.event._def.extendedProps;

      document.querySelector('.event-icon').innerHTML =
        "<i class='fa fa-" + event.icon + "'></i>";

      const openModalButton = document.querySelector(
        '[data-bs-toggle="modal"]'
      );
      if (openModalButton) {
        openModalButton.click();
      }
    },
    handleEventContent(arg) {
      const eventEl = document.createElement('div');
      eventEl.innerHTML = `
      <div class="event-title"><strong>${
        arg.event.extendedProps.name
      }</strong></div>
      <div class="event-description">${arg.event.extendedProps.status}</div>
      <img src=" ${
        baseURL + arg.event.extendedProps.image
      }  " width='50' height='50'>
      <!-- Add other event properties as needed -->
    `;
      return { domNodes: [eventEl] };
    },

    async clearSelectedEventData() {
      this.selectedEvent = {};
      await this.getEvents();
      this.loadCalendar();
      console.log('clear event data');
    },

    loadCalendar() {
      this.calendarEl = document.getElementById('calendar');
      this.calendar = new Calendar(this.calendarEl, {
        timeZone: 'local',
        plugins: [interactionPlugin, dayGridPlugin],
        eventDisplay: 'auto',
        businessHours: false,
        initialView: this.calendarView,
        editable: true,
        headerToolbar: this.calendarHeader,
        titleFormat: this.titleFormat,
        eventContent: this.handleEventContent,
        dateClick: this.dateClick,
        eventClick: this.eventClick,
      });
      this.calendar.setOption(
        'events',
        this.allEvents.map((eventnew) => ({
          id: eventnew.id,
          //title: eventnew.name,
          event_img: eventnew.event_img,
          name: eventnew.name,
          slug: eventnew.event_slug,
          short_description: eventnew.short_description,
          description: eventnew.description,

          start: eventnew.start_date,
          end: eventnew.end_date,
          image: eventnew.image,

          status: eventnew.status,
          category: eventnew.category,
          category_slug: eventnew.category_slug,

          venue: eventnew.venue,
          doors_open: eventnew.doors_open,
          company: eventnew.company,
          start_date: eventnew.start_date,
          end_date: eventnew.end_date,
        }))
      );

      //console.log(this.allEvents);
      //console.log(this.calendar);
      this.calendarEl.addEventListener('dateClick', this.dateClick);
      this.calendarEl.addEventListener('eventClick', this.eventClick);
      this.calendar.render();
      this.$store.state.isLoading = false;
    },
  },

  async mounted() {
    this.$store.state.isLoading = true;
    if (!this.$store.state.isTokenExpired) {
      this.whichView();
      await this.getEvents();
      this.loadCalendar();
      this.resizeCalendar();
    } else {
      this.$store
        .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
        .then(() => {
          this.whichView();
          this.getEvents();
          this.loadCalendar();
          this.resizeCalendar();
        });
    }
    this.$store.state.isLoading = false;

    window.addEventListener('resize', this.resize);
  },
  created() {
    document.title = 'Events Dashboard | VTIX';
  },

  $route(to, from) {
    if (to.path === '/account/dashboard/events') {
      this.getUserProfile();
    }
  },
};
</script>
