<style scoped>
h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #b65c32;
  padding-left: 10px;
  margin-bottom: 30px;
}
</style>
<template>
  <!-- Billing history card-->
  <h1>Orders</h1>
  <div class="card mb-4">
    <div class="card-header">Order History</div>
    <div class="card-body p-0">
      <!-- Billing history table-->
      <div class="table-responsive table-billing-history">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="border-gray-200" scope="col">Transaction ID</th>
              <th class="border-gray-200" scope="col">Date</th>
              <th class="border-gray-200" scope="col">Qty</th>
              <th class="border-gray-200" scope="col">Amount</th>
              <th class="border-gray-200" scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders.results">
              <td>#{{ order.id }}</td>
              <td>{{ order.date_created }}</td>
              <td v-if="order.items">{{ order.items.length }}</td>
              <td v-else>0</td>
              <td>R{{ order.total }}</td>
              <td>
                <span class="badge bg-light text-dark">{{ order.status }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderHistory',

  props: {
    orders: {
      type: Object,
      required: true,
    },
    orderItems: {
      type: Array,
      required: false,
    },
  },
};
</script>
