<style>
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 0;
  border-radius: 0.25rem;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 2rem;
  cursor: pointer;
  margin: 0;
  position: relative;
  box-shadow: 0px 0px 1px #474747;
  border-radius: 50%;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 1rem;
  height: 2px;
  background-color: #212121;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type='number'] {
  max-width: 3rem;
  text-align: center;
}

.multiline {
  white-space: pre-wrap;
}

.fit-image {
  width: 100%;
  object-fit: cover;
  /*height: 300px;  only if you want fixed height */
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>
<template>
  <div class="event">
    <h1></h1>
  </div>
  <div class="row">
    <div class="col-md-12 mb-12">
      <div class="mb-12">
        <div class="container py-5 h-100">
          <div
            class="row d-flex justify-content-center align-items-center h-100"
          >
            <div class="col-12">
              <div
                class="card card-registration card-registration-2"
                style="border-radius: 15px"
              >
                <div class="card-body p-0">
                  <div class="row g-0">
                    <div class="col-lg-8">
                      <div class="p-1">
                        <div class="preview">
                          <div class="preview-pic tab-content">
                            <div class="tab-pane active c" id="pic-1">
                              <img
                                class="col-md-12 fit-image"
                                v-bind:src="event.image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="p-5">
                        <h1 class="product-title">{{ event.name }}</h1>
                        <hr class="my-4" />

                        <div class="d-flex justify-content-between mb-4">
                          <h5 class="text-uppercase">22 tickets left</h5>
                        </div>

                        <hr class="my-2" />
                        <div class="list-group-item">
                          <p class="product-description">
                            {{ event.short_description }}
                          </p>
                          <p class="">
                            <strong>
                              Venue : {{ event.venue_name }} -
                              {{ event.event_city }}</strong
                            >
                          </p>
                          <p class="">
                            <strong>{{ event.time }}</strong>
                          </p>
                          <p class="">
                            <strong
                              >{{ event.start_date }} -
                              {{ event.end_date }}</strong
                            >
                          </p>
                        </div>
                        <h5 class="sizes list-group-item">Tickets:</h5>

                        <div
                          class="container"
                          v-for="(ticket, index) in tickets"
                          v-bind:key="index"
                        >
                          <div
                            v-bind:class="[
                              'badge d-flex text-white-emphasis rounded-3 justify-content-between ',
                              colors[index],
                            ]"
                          >
                            <h5 class="mb-0">
                              {{ ticket.ticket_name }}
                            </h5>
                            <h5 class="mb-0">R{{ ticket.price }}</h5>
                          </div>
                          <div class="b-1"><p></p></div>

                          <div class="d-flex">
                            <button
                              @click="decrementQuantity(index)"
                              class="btn btn-link px-2"
                            >
                              <i class="fas fa-minus"></i>
                            </button>

                            <input
                              min="0"
                              max="9999"
                              type="number"
                              v-model="ticket.quantity"
                              :id="'quantity-' + ticket.id"
                              :name="'quantity-' + ticket.id"
                              class="form-control"
                            />

                            <button
                              @click="incrementQuantity(index)"
                              class="btn btn-link px-2"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>

                          <div class="b-1"><p></p></div>
                        </div>
                        <div class="b-4 list-group-item"><p></p></div>
                        <div class="container list-group-item">
                          <button
                            type="button"
                            class="btn bg-6 text-white"
                            @click="addToCart"
                          >
                            Add To Cart
                          </button>
                          <button class="like btn btn-default" type="button">
                            <span class="fa fa-heart"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CustomToast
      :toastInfo="{
        header: toastInfo.header,
        message: toastInfo.message,
        smallText: toastInfo.smallText,
        show: toastInfo.show,
      }"
    />
  </div>
</template>

<script>
import axios from 'axios';

import { mapState } from 'vuex';
import CustomToast from '@/components/CustomToast.vue';

export default {
  name: 'EventView',

  data() {
    return {
      event: {},
      tickets: [],
      cartTickets: [],
      colors: ['bg-info', 'bg-success', 'bg-warning', 'bg-danger'],
      baseURL: process.env.VUE_APP_BACKEND_URL,
      cartTotalItems: 0,
      order: {},
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
    };
  },
  watch: {},
  mounted() {
    this.getEventDetails();
    this.getEventTickets();
  },
  computed: {
    ...mapState(['cartTotal']),
  },
  methods: {
    getEventDetails() {
      this.$store.state.isLoading = true;
      const category_slug = this.$route.params.slug_category;
      const event_slug = this.$route.params.slug_event;
      const baseURL = process.env.VUE_APP_BACKEND_URL;

      axios
        .get(baseURL + `/api/v1/events/${category_slug}/${event_slug}`)
        .then((response) => {
          this.event = response.data;
          document.title = this.event.name + ' | JusTix';
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.customToast(
            'Error',
            'Error getting event details',
            'Please try again later'
          );
          this.$store.state.isLoading = false;
        });
    },
    getEventTickets() {
      this.$store.state.isLoading = true;
      const category_slug = this.$route.params.slug_category;
      const event_slug = this.$route.params.slug_event;
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      axios
        .get(baseURL + `/api/v1/tickets/${category_slug}/${event_slug}`)
        .then((response) => {
          this.tickets = response.data.map((ticket) => ({
            ...ticket,
            quantity: 0,
          }));
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.isLoading = false;
        });
    },
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },
    async addToCart() {
      /* for (let i = 0; i < this.tickets.length; i++) {
        this.tickets[i].quantity = parseInt(
          document.getElementById(`quantity-${this.tickets[i].id}`).value
        );
      }

      for (let i = 0; i < this.tickets.length; i++) {
        if (this.tickets[i].quantity > 0) {
          this.cartTickets.push(this.tickets[i]);
          let ticketToUpdate = this.cartTickets.findIndex(
            (obj) => obj.id === this.tickets[i].id
          );
          if (
            ticketToUpdate !== -1 &&
            this.cartTickets[ticketToUpdate].id === this.tickets[i].id
          ) {
            this.cartTickets[ticketToUpdate].quantity +=
              this.tickets[i].quantity;
          } else {
            this.cartTickets.push(this.tickets[i]);
          }
        }
      } */
      let tickets = this.tickets.filter((ticket) => ticket.quantity > 0);

      //add event name to the ticket
      for (let i = 0; i < tickets.length; i++) {
        tickets[i].event_name = this.event.name;
        tickets[i].event_image = this.event.image;
        tickets[i].total = tickets[i].quantity * tickets[i].price;
      }

      let totalCartTickets = 0;
      for (let i = 0; i < tickets.length; i++) {
        totalCartTickets += tickets[i].quantity;
      }

      if (tickets.length > 0) {
        await this.$store.commit('addToCart', tickets);
        this.customToast(
          'Success',
          'Tickets added to cart',
          `${totalCartTickets} tickets added to cart`
        );

        tickets = [];
        setTimeout(() => {
          //this.toastInfo.show = false;
        }, 5000);
        //reset the input fields
      }

      for (let i = 0; i < this.tickets.length; i++) {
        //BUG - Add to cart bug caused by this reset. Need to find a way to properly reset fields.
        //this.tickets[i].quantity = 0;
      }
    },

    decrementQuantity(index) {
      // Decrement the quantity for the ticket at the specified index
      if (this.tickets[index].quantity > 0) {
        this.tickets[index].quantity--;
      }
    },
    incrementQuantity(index) {
      // Increment the quantity for the ticket at the specified index
      this.tickets[index].quantity++;
    },

    makeToast(variant = null, title, message) {
      this.$toast.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Message Content',
        life: 3000,
      });

      console.log('toast');
    },
    cartTotal() {
      return this.$store.getters.cartTotal;
    },
  },
  components: {
    CustomToast,
  },
};
</script>
