
import { Vue } from 'vue-class-component';
import axios from 'axios';

export default class Register extends Vue {
  [x: string]: any;
  name = 'Register';
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone: string = '';
  address: string = '';
  city: string = '';
  username: string = '';
  password1: string = '';
  password2: string = '';
  birthday: string = '';
  error: Array<string> = [];
  success: string = '';
  baseURL: string = process.env.VUE_APP_BACKEND_URL;

  isLogin: boolean = false;
  switchLogin() {
    this.$router.push('/login');
  }
  switchReset() {
    this.$router.push('/password-reset/');
  }
  isValidForm() {
    let isValid = true;

    /* if (this.password1 !== this.password2) {
      this.error.push('Passwords do not match');
      isValid = false;
    } */
    if (this.email === '') {
      this.error.push('Email is required');
      isValid = false;
    }
    /*     if (this.password1 === '') {
      this.error.push('Password is required');
      isValid = false;
    } */
    if (this.first_name == '' || this.last_name == '') {
      this.error.push('First and Last name are required');
      isValid = false;
    }

    return isValid;
  }

  doRegistration() {
    if (this.isValidForm()) {
      this.$store.state.isLoading = true;
      this.$store
        .dispatch('userReg', {
          email: this.email,
          //password: this.password1,
          first_name: this.first_name,
          last_name: this.last_name,
          birthday: this.birthday,
          phone: this.phone,
          address: this.address,
          city: this.city,
          profile_type: 'customer',
        })
        .then(() => {
          this.passwordReset(this.email);
          this.success =
            'Registration successful! You will be redirected shortly.';
          this.email = '';
          this.password = '';
          this.first_name = '';
          this.last_name = '';
          this.birthday = '';
          this.phone = '';
          this.city = '';
          setTimeout(() => {
            this.$router.push('/login');
          }, 3000);
          this.$store.state.isLoading = false;
          //this.$router.go(-1);
        })
        .catch((err: any) => {
          this.$store.state.isLoading = false;
          if (err) {
            for (let key in err) {
              //check if key is in object, if it's an object, then loop through it
              if (err.hasOwnProperty(key)) {
                if (typeof err[key] === 'object') {
                  for (let key2 in err[key]) {
                    if (typeof err[key2] === 'object') {
                      this.error.push(err[key][key2][0]);
                    } else {
                      this.error.push(err[key][key2]);
                    }
                  }
                } else {
                  this.error.push(err[key][0]);
                }
              }
            }
          }
        });
    } else {
      alert(...this.error);
    }
  }

  passwordReset(login: string) {
    axios
      .post(`${this.baseURL}/api/v1/account/send-reset-password-link/`, {
        login: login,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  mounted(): void {
    window.setTimeout((): void => {
      const alerts: NodeListOf<Element> = document.querySelectorAll('.alert');
      alerts.forEach((alert: Element): void => {
        (alert as HTMLElement).style.transition = 'opacity 500ms';
        (alert as HTMLElement).style.opacity = '0';
        window.setTimeout((): void => {
          if (alert.parentNode) {
            alert.parentNode.removeChild(alert);
          }
        }, 500);
      });
    }, 3000);
  }
}
