<template>
  <section style="background-color: #eee">
    <div class="container py-5">
      <!-- Account page navigation-->
      <ProfileNav />

      <div class="row">
        <div class="col-md-6">
          <h1>Company Reps</h1>
        </div>
        <div class="col-md-6 text-md-end">
          <a href="#!" class="text-body" @click="goBack">
            <i class="fas fa-long-arrow-alt-left me-2"></i>Back To Dashboard
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <button
            data-bs-toggle="modal"
            data-bs-target="#user-profile"
            type="button"
            class="btn bg-6 ms-3 mr-3 mb-2 btn-lg btn-block"
          >
            <i class="fa fa-user"></i> Add New Rep
          </button>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="row">
            <div
              v-if="companyReps.length > 0"
              class="col-lg-4"
              v-for="rep in companyReps"
            >
              <div class="text-center card-box">
                <div class="member-card pt-2 pb-2">
                  <div class="thumb-lg member-thumb mx-auto">
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar2.png"
                      class="rounded-circle img-thumbnail"
                      alt="profile-image"
                    />
                  </div>
                  <div class="">
                    <h4>{{ rep.user.first_name }} {{ rep.user.last_name }}</h4>
                    <p class="text-muted">
                      {{ rep.phone }}<span>| </span
                      ><span
                        ><a href="#" class="text-pink">{{
                          rep.user.email
                        }}</a></span
                      >
                    </p>
                    <p
                      class="text-muted justify-content-between align-items-center"
                    >
                      {{ rep.province }}<span>| </span
                      ><span
                        ><a href="#">{{ rep.city }}</a></span
                      >
                    </p>
                    <p
                      class="text-muted justify-content-between align-items-center"
                    >
                      {{ rep.sex }}
                    </p>
                  </div>
                  <ul class="social-links list-inline">
                    <li class="list-inline-item">
                      <a
                        title=""
                        data-placement="top"
                        data-toggle="tooltip"
                        class="tooltips"
                        href=""
                        data-original-title="Facebook"
                        ><i class="fa fa-facebook"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        title=""
                        data-placement="top"
                        data-toggle="tooltip"
                        class="tooltips"
                        href=""
                        data-original-title="Twitter"
                        ><i class="fa fa-twitter"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        title=""
                        data-placement="top"
                        data-toggle="tooltip"
                        class="tooltips"
                        href=""
                        data-original-title="Skype"
                        ><i class="fa fa-skype"></i
                      ></a>
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light"
                  >
                    View Rep
                  </button>
                  <div class="mt-4">
                    <div class="row">
                      <div class="col-4">
                        <div class="mt-3">
                          <h4>2563</h4>
                          <p class="mb-0 text-muted">Tickets Redeemed</p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mt-3">
                          <h4>R6952</h4>
                          <p class="mb-0 text-muted">Income amounts</p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mt-3">
                          <h4>1125</h4>
                          <p class="mb-0 text-muted">Total Transactions</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="container text-center">
              <h2>There are currently no reps for your company</h2>
            </div>
            <PaginationComp :paginationData="paginationData" />
            <!-- end col -->
          </div>
          <!-- end row -->
        </div>
        <!-- container -->
      </div>
    </div>
  </section>
  <div
    class="modal fade"
    id="user-profile"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <RepProfile v-bind:company="propCurrentAccount.profile.company" />
  </div>
</template>
<style scoped>
body {
  background: #dcdcdc;
  margin-top: 20px;
}
.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.social-links li a {
  border-radius: 50%;
  color: rgba(121, 121, 121, 0.8);
  display: inline-block;
  height: 30px;
  line-height: 27px;
  border: 2px solid rgba(121, 121, 121, 0.5);
  text-align: center;
  width: 30px;
}

.social-links li a:hover {
  color: #797979;
  border: 2px solid #797979;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.text-pink {
  color: #ff679b !important;
}
.btn-rounded {
  border-radius: 2em;
}
.text-muted {
  color: #98a6ad !important;
}
h4 {
  line-height: 22px;
  font-size: 18px;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #6f5b92;
  padding-left: 10px;
  margin-bottom: 30px;
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>

<script>
import TicketBox from '@/./components/TicketBox.vue';
import EventTicket from '@/./components/EventTicket.vue';
import { BModal } from 'bootstrap-vue';
import ProfileNav from '@/./components/ProfileNav.vue';
import RepProfile from '@/./components/RepProfile.vue';
import PaginationComp from '@/./components/PaginationComp.vue';

import axios from 'axios';
import callAPI from '../../axios-api';
const baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'Account',
  data() {
    return {
      islogin: true,
      userProfileType: '',
      currentUser: {},
      userprofile: {},
      companyReps: [],
      propCurrentAccount: {
        user: {},
        profile: {},
      },
      paginationData: {
        djangoResults: {
          count: 0,
          next: null,
          previous: null,
          results: [],
        },
        currentPage: 0,
        recordsPerpage: 0,
        totalRecords: 0,
        apiEndPoint: '',
        records: [],
        pageSize: 16,
        maxTabs: 5,
        baseURL: process.env.VUE_APP_BACKEND_URL,
      },

      activeprofileNavTab: 'profile',
      isLoading: false,
    };
  },
  created() {
    document.title = 'My Account | VTIX';
  },
  components: {
    TicketBox,
    EventTicket,
    RepProfile,
    ProfileNav,
    PaginationComp,
  },
  mounted() {
    if (!this.$store.getters.isTokenExpired) {
      this.getUserProfile().then(() => {
        this.getCompanyReps();
      });
      this.userProfileType = this.$store.state.profileType;
      this.endPoint =
        '/api/v1/account/company/reps/' + this.userprofile.company + '/';
    } else {
      this.$store
        .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
        .then(() => {
          this.getUserProfile();
          this.userProfileType = this.$store.state.profileType;
        });
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    doLogout() {
      this.$store.commit('clearToken');
      this.$store.commit('clearCart');
      this.$router.push('/');
    },
    async getUserProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;
      await axios
        .get(baseURL + '/api/v1/account/current/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })

        .then((res) => {
          this.currentUser = res.data.user;
          this.userprofile = res.data.profile;
          this.propCurrentAccount.user = res.data.user;
          this.propCurrentAccount.profile = res.data.profile;
          this.$store.commit('setProfileType', res.data.profile.profile_type);
          this.$store.state.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch('reAuthorizeApi', refreshToken).then(() => {
              this.getUserProfile();
            });
          }
          this.$store.state.isLoading = false;
        });
    },
    getCompanyReps() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.staterefresh_token;

      this.$store.state.isLoading = true;
      axios
        .get(
          baseURL +
            '/api/v1/account/company/reps/' +
            this.userprofile.company +
            '/',
          {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
        )

        .then((res) => {
          console.log(res.data);
          this.companyReps = res.data.results;
          this.paginationData.djangoResults = res.data;
          this.paginationData.recordsPerpage = res.data.results.length;
          for (
            let i = 0;
            i <
            Math.ceil(
              this.paginationData.djangoResults.count /
                this.paginationData.djangoResults.results.length
            );
            i++
          ) {
            this.paginationData.records.push(i);
            console.log(i);
          }
          console.log(this.companyReps);
          this.$store.state.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 401) {
            this.$store.dispatch('reAuthorizeApi', refresh_token).then(() => {
              this.getCompanyReps();
            });
          }
          console.log(err);
          this.$store.state.isLoading = false;
        });
    },
    goToMerchantDashboard() {
      this.$router.push('/account/dashboard');
    },
  },

  $route(to, from) {
    if (to.path === '/account/dashboard/reps') {
      this.getUserProfile();
    }
  },
};
</script>
