<style>
.custom-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<template>
  <div
    class="toast custom-toast text-white"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-bs-delay="5000"
    id="toast"
    :class="{
      show: toastInfo.show,
      'bg-danger': toastInfo.header === 'Error',
      'bg-success': toastInfo.header === 'Success',
    }"
  >
    <div class="toast-header">
      <img src="" class="rounded me-2" alt="" />
      <strong class="me-auto">{{ toastInfo.header }}</strong>
      <small>{{ toastInfo.smallText }}</small>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body">{{ toastInfo.message }}</div>
  </div>
</template>

<script>
export default {
  name: 'CustomToast',

  props: {
    toastInfo: {
      message: String,
      header: String,
      smallText: String,
      show: Boolean,
    },
  },

  data() {
    return {};
  },

  methods: {
    showToast() {
      if (this.toastInfo.show) {
        // Get the toast DIV
        var toast = document.getElementById('toast');
        // After 3 seconds, remove the show class from DIV
        setTimeout(() => {
          toast.className = toast.className.replace('show', '');
        }, 5000);
      }
    },
  },

  mounted() {
    this.showToast();
  },

  updated() {
    this.showToast();
  },

  destroyed() {
    //this.showToast();
  },

  watch: {
    toastInfo: {
      handler() {
        this.showToast();
      },
      deep: true,
    },
  },
};
</script>
