<template>
  <section style="background-color: #eee">
    <div class="container py-5">
      <!-- Account page navigation-->
      <ProfileNav />
      <h1>Profile</h1>
      <div class="row">
        <div class="col-lg-4">
          <div class="card mb-4">
            <div class="card-body text-center">
              <img
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt="avatar"
                class="rounded-circle img-fluid"
                style="width: 150px"
              />
              <h5 class="my-3">
                {{ currentUser.first_name }} {{ currentUser.last_name }}
              </h5>
              <p class="text-muted mb-1">
                Last Login: {{ this.formatDate(currentUser.last_login) }}
              </p>

              <div class="d-flex justify-content-center mb-2">
                <button
                  @click="goToMerchantDashboard"
                  type="button"
                  class="btn bg-6"
                >
                  Dashboard
                </button>

                <button
                  data-bs-toggle="modal"
                  data-bs-target="#user-profile"
                  type="button"
                  class="btn bg-6 ms-1"
                >
                  Edit profile
                </button>
                <button @click="doLogout" type="button" class="btn bg-5 ms-1">
                  Logout
                </button>
              </div>
            </div>
          </div>
          <!--
          <div class="card mb-4 mb-lg-0">
            <div class="card-body p-0">
              <ul class="list-group list-group-flush rounded-3">
                <li
                  class="list-group-item d-flex justify-content-between align-items-center p-3"
                >
                  <i class="fas fa-globe fa-lg text-warning"></i>
                  <p class="mb-0">https://mdbootstrap.com</p>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center p-3"
                >
                  <i class="fab fa-github fa-lg" style="color: #333333"></i>
                  <p class="mb-0">mdbootstrap</p>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center p-3"
                >
                  <i class="fab fa-twitter fa-lg" style="color: #55acee"></i>
                  <p class="mb-0">@mdbootstrap</p>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center p-3"
                >
                  <i class="fab fa-instagram fa-lg" style="color: #ac2bac"></i>
                  <p class="mb-0">mdbootstrap</p>
                </li>
                <li
                  class="list-group-item d-flex justify-content-between align-items-center p-3"
                >
                  <i class="fab fa-facebook-f fa-lg" style="color: #3b5998"></i>
                  <p class="mb-0">mdbootstrap</p>
                </li>
              </ul>
            </div>
          </div>
          -->
        </div>
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Full Name</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">
                    {{ currentUser.first_name }} {{ currentUser.last_name }}
                  </p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Email</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ currentUser.email }}</p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Phone</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userprofile.phone }}</p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Address</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userprofile.address }}</p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">City</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userprofile.city }}</p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Suburb</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0"></p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Post Code</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userprofile.post_code }}</p>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Province</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userprofile.province }}</p>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">Race</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userprofile.race }}</p>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="container mt-5 mb-3">
              <div class="row">
                <TicketBox />
                <div class="col-md-4">
                  <div class="card p-3 mb-2">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <div class="icon"><i class="bx bxl-mailchimp"></i></div>
                        <div class="ms-2 c-details">
                          <h6 class="mb-0">Bought</h6>
                          <span>1 days ago</span>
                        </div>
                      </div>
                      <div class="badge"><span>Outdoor</span></div>
                    </div>
                    <div class="mt-5">
                      <h3 class="heading">All white Party</h3>
                      <div class="mt-3">
                        <span class="text1">General Access ticket</span>
                      </div>
                      <span>The OZ Pub - Soweto</span>
                      <span>28 September 2023</span>
                      <div class="mt-5">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 50%"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="mt-3">
                          <span class="text1"
                            >32 days <span class="text2">to event</span></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card p-3 mb-2">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <div class="icon"><i class="bx bxl-mailchimp"></i></div>
                        <div class="ms-2 c-details">
                          <h6 class="mb-0">Bought</h6>
                          <span>1 days ago</span>
                        </div>
                      </div>
                      <div class="badge"><span>Outdoor</span></div>
                    </div>
                    <div class="mt-5">
                      <h3 class="heading">All white Party</h3>
                      <div class="mt-3">
                        <span class="text1">General Access ticket</span>
                      </div>
                      <span>The OZ Pub - Soweto</span>
                      <span>28 September 2023</span>
                      <div class="mt-5">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 50%"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="mt-3">
                          <span class="text1"
                            >32 days <span class="text2">to event</span></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card p-3 mb-2">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <div class="icon"><i class="bx bxl-mailchimp"></i></div>
                        <div class="ms-2 c-details">
                          <h6 class="mb-0">Bought</h6>
                          <span>1 days ago</span>
                        </div>
                      </div>
                      <div class="badge"><span>Outdoor</span></div>
                    </div>
                    <div class="mt-5">
                      <h3 class="heading">All white Party</h3>
                      <div class="mt-3">
                        <span class="text1">General Access ticket</span>
                      </div>
                      <span>The OZ Pub - Soweto</span>
                      <span>28 September 2023</span>
                      <div class="mt-5">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 50%"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="mt-3">
                          <span class="text1"
                            >32 days <span class="text2">to event</span></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-4 mb-md-0">
                <div class="card-body">
                  <p class="mb-4">
                    <span class="text-primary font-italic me-1"></span>
                    Transaction History
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-4 mb-md-0">
                <div class="card-body">
                  <p class="mb-4">
                    <span class="text-primary font-italic me-1"></span>
                    Points
                  </p>
                  <p class="mb-1" style="font-size: 0.77rem">Web Design</p>
                  <div class="progress rounded" style="height: 5px">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 80%"
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p class="mt-4 mb-1" style="font-size: 0.77rem">
                    Website Markup
                  </p>
                  <div class="progress rounded" style="height: 5px">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 72%"
                      aria-valuenow="72"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p class="mt-4 mb-1" style="font-size: 0.77rem">One Page</p>
                  <div class="progress rounded" style="height: 5px">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 89%"
                      aria-valuenow="89"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p class="mt-4 mb-1" style="font-size: 0.77rem">
                    Mobile Template
                  </p>
                  <div class="progress rounded" style="height: 5px">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 55%"
                      aria-valuenow="55"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p class="mt-4 mb-1" style="font-size: 0.77rem">
                    Backend API
                  </p>
                  <div class="progress rounded mb-2" style="height: 5px">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style="width: 66%"
                      aria-valuenow="66"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
  </section>
  <div
    class="modal fade"
    id="user-profile"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <UserProfile v-bind:currentUser="propCurrentAccount" />
  </div>
</template>
<style scoped>
body {
  background-color: #eee;
}

.card {
  border: none;
  border-radius: 10px;
}

.c-details span {
  font-weight: 300;
  font-size: 13px;
}

.badge span {
  background-color: #fffbec;
  width: 60px;
  height: 25px;
  padding-bottom: 3px;
  border-radius: 5px;
  display: flex;
  color: #fed85d;
  justify-content: center;
  align-items: center;
}

.progress {
  height: 10px;
  border-radius: 10px;
}

.progress div {
  background-color: red;
}

.text1 {
  font-size: 14px;
  font-weight: 600;
}

.text2 {
  color: #a5aec0;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #4e4066;
  padding-left: 10px;
  margin-bottom: 30px;
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>

<script>
import TicketBox from '@/components/TicketBox.vue';
import EventTicket from '@/components/EventTicket.vue';
import { BModal } from 'bootstrap-vue';
import UserProfile from '@/components/UserProfile.vue';
import ProfileNav from '@/components/ProfileNav.vue';
import axios from 'axios';
import callAPI from '../axios-api';
const baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  name: 'Account',
  data() {
    return {
      islogin: true,
      userProfileType: '',
      currentUser: {},
      userprofile: {},
      propCurrentAccount: {
        user: {},
        profile: {},
      },

      activeprofileNavTab: 'profile',
      isLoading: false,
    };
  },
  created() {
    document.title = 'My Account | VTIX';
  },
  components: {
    TicketBox,
    EventTicket,
    UserProfile,
    ProfileNav,
  },
  mounted() {
    this.getUserProfile();
    this.userProfileType = this.$store.state.profileType;
  },
  methods: {
    doLogout() {
      this.$store.commit('clearToken');
      this.$store.commit('clearCart');
      this.$router.push('/');
    },
    getUserProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;
      axios
        .get(baseURL + '/api/v1/account/current/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          this.currentUser = res.data.user;
          this.userprofile = res.data.profile;
          this.propCurrentAccount.user = res.data.user;
          this.propCurrentAccount.profile = res.data.profile;
          this.$store.commit('setProfileType', res.data.profile.profile_type);
          this.$store.state.isLoading = false;
        })
        .catch((err) => {
          this.$store.state.isLoading = false;
          if (err.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', refreshToken)
              .then((res) => {
                this.getUserProfile();
              })
              .catch((err) => {
                this.$store.state.isLoading = false;
              });
          } else {
            this.$store.state.isLoading = false;
          }
        });
    },
    goToMerchantDashboard() {
      this.$store.state.isLoading = true;
      if (this.userprofile.profile_type == 'merchant') {
        this.$router.push('/account/dashboard');
      }
      if (this.userprofile.profile_type == 'rep') {
        this.$router.push('/account/rep-dashboard');
      }
      this.$store.state.isLoading = false;
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
      return new Date(date).toLocaleString('en-US', options);
    },

    /* getUserProfile() {
      let token = this.$store.state.token;
      axios.get('http://127.0.0.1:8000/api/user/', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
    }, */
  },
};
</script>
