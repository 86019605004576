
import { Vue } from 'vue-class-component';

export default class Login extends Vue {
  [x: string]: any;
  name = 'Login';
  username: string = '';
  password: string = '';
  isLogin: boolean = true;
  validForm: boolean = false;
  error: Array<string> = [];
  baseURL: string = process.env.VUE_APP_BACKEND_URL;
  currentUser: any = null;

  switchLogin() {
    this.$router.push('/register/');
  }
  switchReset() {
    this.$router.push('/password-reset/');
  }
  isValidForm() {
    return this.ussrname !== '' && this.password !== '';
  }

  doLogin() {
    if (this.isValidForm()) {
      this.$store
        .dispatch('userLogin', {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: 'Account' });
        })
        .catch((err: { data: { detail: string } }) => {
          console.log(err);
          this.error.push(err.data.detail);
        });
    } else {
      alert('Unable to login, please contact the system administrator');
    }
  }
}
