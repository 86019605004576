<style scoped>
.profile-pic-wrapper {
  width: 100%;
}
.pic-holder {
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;

  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pic-holder .pic {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder .upload-file-block {
  cursor: pointer;
}

.pic-holder:hover .upload-file-block,
.uploadProfileInput:focus ~ .upload-file-block {
  opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
  display: none;
}

.pic-holder.uploadInProgress .upload-loader {
  opacity: 1;
}

/* Snackbar css */
.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 14px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
<template>
  <!-- Modal -->

  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Update Company Details
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-xl-12">
          <!-- Account details card-->
          <div class="card mb-4">
            <div class="card-header">Company Details</div>
            <div class="card-body">
              <form>
                <!-- Form Group (username)-->
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <div class="profile-pic-wrapper">
                    <div class="pic-holder">
                      <!-- uploaded pic shown here -->
                      <img id="profilePic" class="pic" :src="company_logo" />

                      <Input
                        class="uploadProfileInput"
                        type="file"
                        @change="onChangeFileUpload($event)"
                        name="profile_pic"
                        id="newProfilePhoto"
                        accept="image/*"
                        style="opacity: 0"
                      />
                      <label for="newProfilePhoto" class="upload-file-block">
                        <div class="text-center">
                          <div class="mb-2">
                            <i class="fa fa-camera fa-2x"></i>
                          </div>
                          <div class="text-uppercase">
                            Update <br />
                            Profile Photo
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row gx-3 mb-3">
                  <!-- Form Group (first name)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputFirstName"
                      >Company name</label
                    >
                    <input
                      class="form-control"
                      id="inputFirstName"
                      type="text"
                      placeholder="Enter your first name"
                      v-model="company.name"
                    />
                  </div>
                  <!-- Form Group (last name)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputLastName"
                      >Company Reg NO.</label
                    >
                    <input
                      class="form-control"
                      id="inputLastName"
                      type="text"
                      placeholder="Enter your last name"
                    />
                  </div>
                </div>
                <!-- Form Row        -->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (email address)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email address</label
                    >
                    <input
                      class="form-control"
                      id="inputEmailAddress"
                      type="email"
                      placeholder="Enter your email address"
                      v-model="company.email"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="small mb-1" for="inputPhone"
                      >Phone number</label
                    >
                    <input
                      class="form-control"
                      id="inputPhone"
                      type="tel"
                      placeholder="Enter your phone number"
                      v-model="company.phone"
                    />
                  </div>

                  <!-- Form Group (location)-->
                  <div class="col-md-12">
                    <label class="small mb-1" for="inputLocation"
                      >Address</label
                    >
                    <input
                      class="form-control"
                      id="inputLocation"
                      type="text"
                      placeholder="Enter your location"
                      v-model="company.address"
                    />
                  </div>
                </div>
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (phone number)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="city">Suburb</label>
                    <input
                      class="form-control"
                      id="suburb"
                      type="text"
                      name="suburb"
                      placeholder="Enter your suburb"
                      v-model="company.suburb"
                    />
                  </div>
                  <!-- Form Group (birthday)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="province">Post Code</label>
                    <input
                      class="form-control"
                      id="post-code"
                      type="text"
                      name="post-code"
                      placeholder="Enter your postal code"
                      v-model="company.post_code"
                    />
                  </div>
                </div>
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (phone number)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="city">City</label>
                    <input
                      class="form-control"
                      id="city"
                      type="text"
                      placeholder="Enter your city"
                      v-model="company.city"
                    />
                  </div>
                  <!-- Form Group (birthday)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="province">Province</label>
                    <input
                      class="form-control"
                      id="province"
                      type="text"
                      name="province"
                      placeholder="Enter your province"
                      v-model="company.province"
                    />
                  </div>
                </div>

                <!-- Form Row-->

                <!-- Save changes button-->
                <button
                  @click="updateCompany"
                  class="btn btn-outline-info"
                  type="button"
                >
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import axios from 'axios';
import callAPI from '../axios-api';
import { Vue } from 'vue-class-component';
import CustomToast from './CustomToast.vue';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'CompanyProfile',
  props: {
    company: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
      company_logo: null,
    };
  },
  components: {
    CustomToast,
  },
  methods: {
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },
    updateCompany() {
      const companyFormData = new FormData();
      if (this.company_logo != null) {
        companyFormData.append('logo', this.company_logo);
      }
      companyFormData.append('name', this.company.name);
      companyFormData.append('slug', this.company.slug);

      companyFormData.append('website', this.company.website);
      companyFormData.append('email', this.company.email);
      companyFormData.append('phone', this.company.phone);
      companyFormData.append('address', this.company.address);
      companyFormData.append('city', this.company.city);
      companyFormData.append('suburb', this.company.suburb);
      companyFormData.append('post_code', this.company.post_code);
      companyFormData.append('province', this.company.province);
      companyFormData.append('country', this.company.country);

      axios
        .put(
          baseURL + '/api/v1/account/company/',

          companyFormData,

          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((response) => {
                //console.log(response);
                this.updateCompany();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status == 200) {
            this.customToast(
              'Success',
              'Company details updated successfully',
              '',
              true
            );
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((response) => {
                //console.log(response);
                this.updateCompany();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },

    logoUpload() {
      document.addEventListener('change', (event) => {
        if (event.target.classList.contains('uploadProfileInput')) {
          var triggerInput = event.target;
          var currentImg = triggerInput
            .closest('.pic-holder')
            .querySelector('.pic').src;
          var holder = triggerInput.closest('.pic-holder');
          var wrapper = triggerInput.closest('.profile-pic-wrapper');

          var alerts = wrapper.querySelectorAll('[role="alert"]');
          alerts.forEach((alert) => {
            alert.remove();
          });

          triggerInput.blur();
          var files = triggerInput.files || [];
          if (!files.length || !window.FileReader) {
            return;
          }

          if (/^image/.test(files[0].type)) {
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onloadend = () => {
              holder.classList.add('uploadInProgress');

              console.log(this.result);
              holder.querySelector('.pic').src = this.result;

              var loader = document.createElement('div');
              loader.classList.add('upload-loader');
              loader.innerHTML =
                '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>';
              holder.appendChild(loader);

              setTimeout(function () {
                holder.classList.remove('uploadInProgress');
                loader.remove();

                var random = Math.random();
                if (random < 0.9) {
                  wrapper.innerHTML +=
                    '<div class="snackbar show" role="alert"><i class="fa fa-check-circle text-success"></i> Profile image updated successfully</div>';
                  triggerInput.value = '';
                  setTimeout(function () {
                    wrapper.querySelector('[role="alert"]').remove();
                  }, 3000);
                } else {
                  holder.querySelector('.pic').src = currentImg;
                  wrapper.innerHTML +=
                    '<div class="snackbar show" role="alert"><i class="fa fa-times-circle text-danger"></i> There is an error while uploading! Please try again later.</div>';
                  triggerInput.value = '';
                  setTimeout(function () {
                    wrapper.querySelector('[role="alert"]').remove();
                  }, 3000);
                }
              }, 1500);
            };
          } else {
            wrapper.innerHTML +=
              '<div class="alert alert-danger d-inline-block p-2 small" role="alert">Please choose a valid image.</div>';
            setTimeout(function () {
              var invalidAlert = wrapper.querySelector('[role="alert"]');
              if (invalidAlert) {
                invalidAlert.remove();
              }
            }, 3000);
          }
        }
      });

      // Vanilla Script Version
      /* document.addEventListener("change", function(event) {
  if (event.target.classList.contains("uploadProfileInput")) {
    var triggerInput = event.target;
    var currentImg = triggerInput.closest(".pic-holder").querySelector(".pic").src;
    var holder = triggerInput.closest(".pic-holder");
    var wrapper = triggerInput.closest(".profile-pic-wrapper");

    var alerts = wrapper.querySelectorAll('[role="alert"]');
    alerts.forEach(function(alert) {
      alert.remove();
    });

    triggerInput.blur();
    var files = triggerInput.files || [];
    if (!files.length || !window.FileReader) {
      return;
    }

    if (/^image/.test(files[0].type)) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onloadend = function() {
        holder.classList.add("uploadInProgress");
        holder.querySelector(".pic").src = this.result;

        var loader = document.createElement("div");
        loader.classList.add("upload-loader");
        loader.innerHTML = '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>';
        holder.appendChild(loader);

        setTimeout(function() {
          holder.classList.remove("uploadInProgress");
          loader.remove();

          var random = Math.random();
          if (random < 0.9) {
            wrapper.innerHTML += '<div class="snackbar show" role="alert"><i class="fa fa-check-circle text-success"></i> Profile image updated successfully</div>';
            triggerInput.value = "";
            setTimeout(function() {
              wrapper.querySelector('[role="alert"]').remove();
            }, 3000);
          } else {
            holder.querySelector(".pic").src = currentImg;
            wrapper.innerHTML += '<div class="snackbar show" role="alert"><i class="fa fa-times-circle text-danger"></i> There is an error while uploading! Please try again later.</div>';
            triggerInput.value = "";
            setTimeout(function() {
              wrapper.querySelector('[role="alert"]').remove();
            }, 3000);
          }
        }, 1500);
      };
    } else {
      wrapper.innerHTML += '<div class="alert alert-danger d-inline-block p-2 small" role="alert">Please choose a valid image.</div>';
      setTimeout(function() {
        var invalidAlert = wrapper.querySelector('[role="alert"]');
        if (invalidAlert) {
          invalidAlert.remove();
        }
      }, 3000);
    }
  }
});
*/
    },

    async uploadLogo() {
      document.addEventListener('change', async (event) => {
        const triggerInput = event.target;

        if (!triggerInput.classList.contains('uploadProfileInput')) {
          return;
        }

        const holder = triggerInput.closest('.pic-holder');
        const wrapper = triggerInput.closest('.profile-pic-wrapper');
        const alerts = wrapper.querySelectorAll('[role="alert"]');

        for (const alert of alerts) {
          alert.remove();
        }

        triggerInput.blur();
        const files = triggerInput.files || [];

        if (
          !files.length ||
          !window.FileReader ||
          !/^image/.test(files[0].type)
        ) {
          displayErrorMessage(wrapper);
          return;
        }

        try {
          const imgDataURL = await readImageAsDataURL(files[0]);
          holder.classList.add('uploadInProgress');
          holder.querySelector('.pic').src = imgDataURL;
          wrapper.querySelector('.pic').src = imgDataURL;
          displaySuccessMessage(wrapper);
        } catch (error) {
          displayErrorMessage(wrapper);
        }
      });

      function readImageAsDataURL(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve(reader.result);
          };

          reader.onerror = () => {
            reject(new Error('Failed to read image.'));
          };

          reader.readAsDataURL(file);
        });
      }

      function displayErrorMessage(wrapper) {
        const invalidAlert = document.createElement('div');
        invalidAlert.classList.add(
          'alert',
          'alert-danger',
          'd-inline-block',
          'p-2',
          'small'
        );
        invalidAlert.setAttribute('role', 'alert');
        invalidAlert.textContent = 'Please choose a valid image.';
        wrapper.appendChild(invalidAlert);

        setTimeout(() => {
          invalidAlert.remove();
        }, 3000);
      }

      function displaySuccessMessage(wrapper) {
        const successAlert = document.createElement('div');
        successAlert.classList.add('snackbar', 'show');
        successAlert.setAttribute('role', 'alert');
        successAlert.innerHTML =
          '<i class="fa fa-check-circle text-success"></i> Profile image updated successfully';
        wrapper.appendChild(successAlert);

        setTimeout(() => {
          successAlert.remove();
        }, 3000);
      }
    },

    onChangeFileUpload(event) {
      this.company_logo = event.target.files[0];
    },
  },

  mounted() {
    this.uploadLogo();
  },
};
</script>
