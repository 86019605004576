
import axios from 'axios';
import { Vue } from 'vue-class-component';

export default class PasswordReset extends Vue {
  [x: string]: any;
  name = 'PasswordReset';
  username: string = '';
  password: string = '';
  isLogin: boolean = true;
  validForm: boolean = false;
  error: Array<string> = [];
  success = '';
  baseURL: string = process.env.VUE_APP_BACKEND_URL;
  currentUser: any = null;

  switchLogin() {
    this.$router.push('/login');
  }
  isValidForm() {
    return this.ussrname !== '';
  }

  doLogin() {
    if (this.isValidForm()) {
      this.$store
        .dispatch('userLogin', {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: 'Account' });
        })
        .catch((err: { data: { detail: string } }) => {
          console.log(err);
          this.error.push(err.data.detail);
        });
    } else {
      alert('Unable to login, please contact the system administrator');
    }
  }
  passwordReset() {
    this.$store.state.isLoading = true;
    this.success = '';
    axios
      .post(`${this.baseURL}/api/v1/account/send-reset-password-link/`, {
        login: this.username,
      })
      .then((res) => {
        this.$store.state.isLoading = false;
        if (res.status === 200) {
          this.success =
            'If a user with this email/phone number exists, a password reset link will be sent to your email & phone number.';
        } else {
          this.$store.state.isLoading = false;
          this.error.push(res.data.detail);
        }
      })
      .catch((err) => {
        this.$store.state.isLoading = false;
        this.error.push(err.data.detail);
      });
  }
}
