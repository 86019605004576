<template>
  <Register />
</template>

<script>
import axios from 'axios';

import Register from '@/components/Register.vue';
export default {
  name: 'SignupPage',
  data() {
    return {
      islogin: true,
    };
  },
  components: {
    Register,
  },
  methods: {
    doLogin() {
      if (this.isValidForm()) {
        axios
          .post(baseURL + '/api/v1/jwt/create', {
            username: this.username,
            password: this.password,
          })
          .then((response) => {
            if (response.data.status === 'success') {
              this.$store.commit('addToken', response.data.access);
              /* localStorage.setItem(
                          'user',
                          JSON.stringify(response.data.user)
                        ); */
              this.$router.push('/account');
            } else {
              alert(response.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert('Please enter username and password');
      }
    },
    swicthForm() {
      this.islogin = !this.islogin;
    },
  },

  mounted() {
    //this.showToast();
    document.title = 'Create account | VTIX';
  },
};
</script>
