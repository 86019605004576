<style>
.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>
<template>
  <section class="h-100 h-custom">
    <div class="container h-100 py-5">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="h5">Shopping Bag</th>
                  <th scope="col">Format</th>

                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  scope="row"
                  v-for="(ticket, index) in cart.items"
                  v-bind:key="index"
                >
                  <th>
                    <div class="d-flex align-items-center">
                      <img
                        v-bind:src="ticket.event_image"
                        class="img-fluid rounded-3"
                        style="width: 90px"
                        v-bind:alt="ticket.ticket_name"
                      />
                      <div class="flex-column ms-4">
                        <p class="mb-2">{{ ticket.ticket_name }}</p>
                        <p class="mb-0">{{ ticket.event_name }}</p>
                      </div>
                    </div>
                  </th>
                  <td class="align-middle">
                    <p class="mb-0" style="font-weight: 500">Digital</p>
                  </td>

                  <td class="align-middle">
                    <p class="mb-0" style="font-weight: 500">
                      R{{ formatPrice(ticket.price * ticket.quantity) }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="card shadow-2-strong mb-5 mb-lg-0"
            style="border-radius: 16px"
          >
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-6 col-lg-4 col-xl-3 mb-4 mb-md-0">
                  <form>
                    <div class="d-flex flex-row pb-3">
                      <div class="d-flex align-items-center pe-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radioNoLabel"
                          id="radioNoLabel1v"
                          value=""
                          aria-label="..."
                          checked
                        />
                      </div>
                      <div class="rounded border w-100 p-3">
                        <p class="d-flex align-items-center mb-0">
                          <i
                            class="fab fa-cc-mastercard fa-2x text-dark pe-2"
                          ></i
                          >Credit Card
                        </p>
                      </div>
                    </div>
                    <div class="d-flex flex-row pb-3">
                      <div class="d-flex align-items-center pe-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radioNoLabel"
                          id="radioNoLabel2v"
                          value=""
                          aria-label="..."
                        />
                      </div>
                      <div class="rounded border w-100 p-3">
                        <p class="d-flex align-items-center mb-0">
                          <i
                            class="fab fa-cc-visa fa-2x fa-lg text-dark pe-2"
                          ></i
                          >Debit Card
                        </p>
                      </div>
                    </div>
                    <div class="d-flex flex-row">
                      <div class="d-flex align-items-center pe-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="radioNoLabel"
                          id="radioNoLabel3v"
                          value=""
                          aria-label="..."
                        />
                      </div>
                      <div class="rounded border w-100 p-3">
                        <p class="d-flex align-items-center mb-0">
                          <i
                            class="fab fa-cc-paypal fa-2x fa-lg text-dark pe-2"
                          ></i
                          >PayPal
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-6">
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <div class="form-outline mb-4 mb-xl-5">
                        <input
                          type="text"
                          id="typeName"
                          class="form-control form-control-lg"
                          siez="17"
                          placeholder="John Smith"
                        />
                        <label class="form-label" for="typeName"
                          >Name on card</label
                        >
                      </div>

                      <div class="form-outline mb-4 mb-xl-5">
                        <input
                          type="text"
                          id="typeExp"
                          class="form-control form-control-lg"
                          placeholder="MM/YY"
                          size="7"
                          minlength="7"
                          maxlength="7"
                        />
                        <label class="form-label" for="typeExp"
                          >Expiration</label
                        >
                      </div>
                    </div>
                    <div class="col-12 col-xl-6">
                      <div class="form-outline mb-4 mb-xl-5">
                        <input
                          type="text"
                          id="typeText"
                          class="form-control form-control-lg"
                          siez="17"
                          placeholder="1111 2222 3333 4444"
                          minlength="19"
                          maxlength="19"
                        />
                        <label class="form-label" for="typeText"
                          >Card Number</label
                        >
                      </div>

                      <div class="form-outline mb-4 mb-xl-5">
                        <input
                          type="password"
                          id="typeText"
                          class="form-control form-control-lg"
                          placeholder="&#9679;&#9679;&#9679;"
                          size="1"
                          minlength="3"
                          maxlength="3"
                        />
                        <label class="form-label" for="typeText">Cvv</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-xl-3">
                  <div
                    class="d-flex justify-content-between"
                    style="font-weight: 500"
                  >
                    <p class="mb-2">Subtotal</p>
                    <p class="mb-2">R{{ formatPrice(cartTotal()) }}</p>
                  </div>

                  <hr class="my-4" />
                  formatPrice
                  <div
                    class="d-flex justify-content-between mb-4"
                    style="font-weight: 500"
                  >
                    <p class="mb-2">Total (tax included)</p>
                    <p class="mb-2"></p>
                  </div>

                  <button
                    @click="checkOutCart"
                    type="button"
                    class="btn bg-6 btn-block btn-lg"
                  >
                    <div class="justify-content-between">
                      <span class="mb-2">Checkout </span>
                      <span class="mb-2">R{{ formatPrice(cartTotal()) }}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>
<script>
import 'bootstrap';
import callAPI from '../axios-api';
import CustomToast from '../components/CustomToast.vue';
document.title = 'Complete checkout | VTIX';

export default {
  data() {
    return {
      cart: {
        items: [],
      },
      order: {},
      userAccount: {
        user: {},
        profile: {},
      },
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
    };
  },
  watch: {
    cart: {
      handler() {
        this.cart = this.$store.state.cart;
      },
      deep: true,
    },
  },

  mounted() {
    this.cart = this.$store.state.cart;
    this.getUserProfile();
  },
  methods: {
    formatPrice(price) {
      return price.toFixed(2);
    },
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },

    ticketTotal(ticket) {
      return ticket.quantity * ticket.price;
    },

    cartTotal() {
      let total = 0;
      this.$store.state.cart.items.forEach((item) => {
        total += item.quantity * item.price;
      });
      return total;
    },
    getUserProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;

      callAPI
        .get('/api/v1/account/current/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          this.userAccount.user = res.data.user;
          this.userAccount.profile = res.data.profile;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', refreshToken)
              .then((res) => {
                this.getUserProfile();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });

      this.$store.state.isLoading = false;
    },

    async checkOutCart() {
      //Creating order ans set as pending
      this.$store.state.isLoading = true;
      if (this.$store.state.user_id) {
        const orderResult = await this.createOrder('pending');
        const orderItemResult = await this.createOrderItems(
          orderResult.data.id
        );
        //this.order = orderResult.data;

        //Process payment

        const paymet_status = true;
        const authTicketItems = [];
        const createdVtixItems = [];

        if (paymet_status) {
          const transactionResult = await this.createTransaction(
            'success',
            orderResult.data.id
          );

          await callAPI
            .get('/api/v1/order/' + orderResult.data.id)
            .then((response) => {
              this.orders = response.data;
              console.log(this.orders);

              orderItemResult.forEach((item) => {
                for (let i = 0; i < item.data.quantity; i++) {
                  authTicketItems.push(item.data);
                }
              });
            });

          try {
            const vtixResult = await this.createAuthTicket(authTicketItems);
          } catch (error) {
            // Handle errors if needed
            this.$store.state.cart.items = [];
            this.order = {};
            this.$store.commit('clearCart');
            this.$store.state.isLoading = false;
            console.log(error);
          }
        } else {
          this.$store.state.isLoading = false;
          /*   this.$router.push({
            name: 'OrderFailed',
            params: { order_id: this.order.id },
          }); */
        }
      } else {
        this.$router.push({
          name: 'Login',
          query: { route: 'Pament' },
        });
      }
      this.$store.state.isLoading = false;
      this.$store.state.cart.items = [];
      this.order = {};
      this.$store.commit('clearCart');
      this.customToast(
        'Success',
        'Your order has been completed successfully',
        'Tickets created successfully'
      );

      //Process payment

      //Create vtix
    },

    createOrder(order_status) {
      // Create an order from the cart
      let customer = '';
      if (this.$store.state.user_id) {
        customer = this.$store.state.user_id;
      }

      let order = {
        status: order_status,
        customer: customer,
        first_name: this.userAccount.user.first_name,
        last_name: this.userAccount.user.last_name,
        email: this.userAccount.user.email,
        phone: this.userAccount.profile.phone,
        address: this.userAccount.profile.address,
        city: this.userAccount.profile.city,
        province: this.userAccount.profile.province,
        postcode: this.userAccount.profile.postcode,
        total: this.cartTotal(),
      };
      const orderAPICall = callAPI
        .post('/api/v1/orders/', order)
        .then((response) => {
          return response;
        });

      return orderAPICall;
    },
    createOrderItems(order_id) {
      let orderItemResult = [];
      const orderItems = this.$store.state.cart.items.forEach((item) => {
        let order_item = {
          ticket_order: order_id,
          ticket: item.id,
          quantity: item.quantity,
          total: item.quantity * item.price,
          customer: this.$store.state.user_id,
        };
        const orderItemAPICall = callAPI
          .post('/api/v1/orders/items/', order_item)
          .then((resp) => {
            orderItemResult.push(resp);
          });
      });
      return orderItemResult;
    },
    createTransaction(status, order_id) {
      let transaction = {
        order: order_id,
        tran_ext_id: '123456789',
        payment: 1,
        amount: this.cartTotal(),
        currency: 'ZAR',
        status: status,
        description: 'Test transaction',
      };
      const transactionAPICall = callAPI
        .post('/api/v1/transactions/', transaction, {
          header: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        })
        .then((resp) => {
          return resp;
        });
      return transactionAPICall;
    },
    async createVtix(ticket, order) {
      let vtix = {
        ticket: ticket,
        order: order,
        customer: this.$store.state.user_id,
      };
      try {
        const vtixCreateAPICall = await callAPI
          .post('/api/v1/digital/tickets/', vtix, {
            header: {
              Authorization: `JWT ${this.$store.state.token}`,
            },
          })
          .then((resp) => {
            console.log(resp);
            return resp;
          });
        console.log(vtixCreateAPICall);
        return vtixCreateAPICall;
      } catch (error) {
        console.log(error.response.data);
      }
    },

    createAuthTicket(order_items) {
      let authTicketItemResult = [];
      const orderItems = order_items;

      orderItems.forEach(async (item) => {
        let authTicket = {
          order_item: item.id,
          ticket: item.ticket,
          customer: item.customer,
        };

        const vtixCreateAPICall = await callAPI
          .post('/api/v1/digital/tickets/', authTicket, {
            header: {
              Authorization: `JWT ${this.$store.state.token}`,
            },
          })
          .then((resp) => {
            console.log(resp);
            authTicketItemResult.push(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      return authTicketItemResult;
    },
    addVtixToOrder(vtix, order) {
      let vtixOrder = {
        order: order.id,
        vtix: vtix.id,
      };
      const vtixOrderAPICall = callAPI
        .post('/api/v1/vtix/order/' + order.id, vtixOrder, {
          header: {
            Authorization: `JWT ${this.$store.state.token}`,
          },
        })
        .then((resp) => {
          return resp;
        });
    },
  },
  components: {
    CustomToast,
  },
  computed: {
    mapState() {
      return this.$store.state;
    },
  },
};
</script>
