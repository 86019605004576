import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import axios from 'axios';
import callAPI from '../axios-api';

import store from '../store';
import HomeView from '../views/HomeView.vue';

import Events from '../views/Events.vue';
import SingleEvent from '../views/SingleEvent.vue';

import Venues from '../views/Venues.vue';
import VenueDetail from '../views/VenueDetail.vue';

import Cart from '../views/CartView.vue';
import Payment from '../views/Payment.vue';
import Checkout from '../views/CheckoutView.vue';

import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import ResetPassword from '../views/ResetPassword.vue';
import PasswordResetConfirm from '../views/PasswordResetConfirm.vue';

import MerchantDashboard from '../views/MerchantViews/MerchantDashboard.vue';
import MerchantEvents from '../views/MerchantViews/MerchantEvents.vue';
import MerchantOrders from '../views/MerchantViews/MerchantOrders.vue';
import MerchantReps from '../views/MerchantViews/MerchantReps.vue';

import RepDashboard from '../views/RepDashboard.vue';

import TicketScanner from '../views/TicketScanner.vue';

import Account from '../views/Account.vue';
import Orders from '../views/Orders.vue';
import Tickets from '../views/Tickets.vue';
import AccountNotifications from '../views/AccountNotifications.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/about/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/events/',
    name: 'Events',
    component: Events,
  },
  {
    path: '/events/:slug_category/:slug_event/',
    name: 'Event',
    component: SingleEvent,
  },
  {
    path: '/venues',
    name: 'Venues',
    component: Venues,
  },
  {
    path: '/venues/:slug_category/:slug_venue/',
    name: 'VenueDetail',
    component: VenueDetail,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/payment/',
    name: 'Payment',
    component: Payment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register/',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/password-reset/',
    name: 'PasswordReset',
    component: ResetPassword,
  },
  {
    path: '/password-reset/confirm/',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
  },
  {
    path: '/account/profile/',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/account/orders/',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/tickets/',
    name: 'Tickets',
    component: Tickets,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/notifications/',
    name: 'AccountNotifications',
    component: AccountNotifications,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/account/dashboard/',
    name: 'Dashboard',
    component: MerchantDashboard,
    meta: {
      requiresMerchantProfile: true,
      requiresAuth: true,
    },
  },
  {
    path: '/account/dashboard/events/',
    name: 'MerchantEvents',
    component: MerchantEvents,
    meta: {
      requiresMerchantProfile: true,
      requiresAuth: true,
    },
  },
  {
    path: '/account/dashboard/orders/',
    name: 'merchantorders',
    component: MerchantOrders,
    meta: {
      requiresMerchantProfile: true,
      requiresAuth: true,
    },
  },
  {
    path: '/account/dashboard/reps/',
    name: 'MerchantReps',
    component: MerchantReps,
    meta: {
      requiresMerchantProfile: true,
      requiresAuth: true,
    },
  },
  {
    path: '/account/dashboard/scanner/',
    name: 'TicketScanner',
    component: TicketScanner,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/rep-dashboard/',
    name: 'RepDashboard',
    component: RepDashboard,
    meta: {
      requiresRepProfile: true,
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.js

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next('/login');
    } else if (to.meta.requiresMerchantProfile) {
      const getUserProfile = () => {
        if (!store.getters.isTokenExpired) {
          const token = store.state.token;
          const refreshToken = store.state.refresh_token;
          const baseURL = process.env.VUE_APP_API_URL;

          let userprofile = {};

          store.state.isLoading = true;
          callAPI
            .get('/api/v1/account/current/', {
              headers: {
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              userprofile = res.data.profile;

              store.commit('setProfileType', res.data.profile.profile_type);
              store.state.isLoading = false;
              if (
                res.data.profile.profile_type === 'merchant' ||
                res.data.profile.profile_type === 'rep'
              ) {
                next();
              } else {
                next('/account/profile/');
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response.status == 401) {
                store
                  .dispatch('reAuthorizeApi', refreshToken)
                  .then((res) => {
                    getUserProfile();
                  })
                  .catch((err) => {
                    console.log(err);
                    next('/account/profile');
                  });

                /* callAPI
                .post('/api/auth/refresh/', {
                  refresh: refreshToken,
                })
                .then((res) => {
                  const token = res.data.access;
                  const refreshToken = res.data.refresh;
                  //const userID = res.data.user.id;
                  this.$store.commit('setToken', { token, refreshToken });
                  console.log(res.data);
                  this.getUserProfile();
                })
                .catch((err) => {
                  console.log(err);
                }); */
              } else {
                console.log(err);
                next('/account/profile');
              }
            });
          store.state.isLoading = false;
        } else {
          store
            .dispatch('reAuthorizeApi', store.state.refresh_token)
            .then((res) => {
              getUserProfile();
            })
            .catch((err) => {
              console.log(err);
              next('/account/profile');
            });
        }
      };
      getUserProfile();
    } else if (to.meta.requiresRepProfile) {
      const getUserProfile = () => {
        if (!store.getters.isTokenExpired) {
          const token = store.state.token;
          const refreshToken = store.state.refresh_token;
          const baseURL = process.env.VUE_APP_API_URL;

          let userprofile = {};

          store.state.isLoading = true;
          callAPI
            .get('/api/v1/account/current/', {
              headers: {
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              userprofile = res.data.profile;

              store.commit('setProfileType', res.data.profile.profile_type);
              store.state.isLoading = false;
              if (
                res.data.profile.profile_type === 'merchant' ||
                res.data.profile.profile_type === 'rep'
              ) {
                next();
              } else {
                next('/account/profile/');
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response.status == 401) {
                store
                  .dispatch('reAuthorizeApi', refreshToken)
                  .then((res) => {
                    getUserProfile();
                  })
                  .catch((err) => {
                    console.log(err);
                    next('/account/profile');
                  });

                /* callAPI
                .post('/api/auth/refresh/', {
                  refresh: refreshToken,
                })
                .then((res) => {
                  const token = res.data.access;
                  const refreshToken = res.data.refresh;
                  //const userID = res.data.user.id;
                  this.$store.commit('setToken', { token, refreshToken });
                  console.log(res.data);
                  this.getUserProfile();
                })
                .catch((err) => {
                  console.log(err);
                }); */
              } else {
                console.log(err);
                next('/account/profile');
              }
            });
          store.state.isLoading = false;
        } else {
          store
            .dispatch('reAuthorizeApi', store.state.refresh_token)
            .then((res) => {
              getUserProfile();
            })
            .catch((err) => {
              console.log(err);
              next('/account/profile');
            });
        }
      };
      getUserProfile();
    } else {
      next();
      return;
    }
  } else {
    next();
  }
});

export default router;
