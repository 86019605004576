<style scoped>
.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>

<template>
  <!-- Modal -->

  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Update Account Details
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-xl-12">
          <!-- Account details card-->
          <div class="card mb-4">
            <div class="card-header">Account Details</div>
            <div class="card-body">
              <form>
                <!-- Form Group (username)-->
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (first name)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputFirstName"
                      >First name</label
                    >
                    <input
                      class="form-control"
                      id="inputFirstName"
                      type="text"
                      placeholder="Enter your first name"
                      v-model="currentUser.user.first_name"
                    />
                  </div>
                  <!-- Form Group (last name)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputLastName"
                      >Last name</label
                    >
                    <input
                      class="form-control"
                      id="inputLastName"
                      type="text"
                      placeholder="Enter your last name"
                      v-model="currentUser.user.last_name"
                    />
                  </div>
                </div>
                <!-- Form Row        -->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (email address)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email address</label
                    >
                    <input
                      class="form-control"
                      id="inputEmailAddress"
                      type="email"
                      placeholder="Enter your email address"
                      v-model="currentUser.user.email"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="small mb-1" for="inputPhone"
                      >Phone number</label
                    >
                    <input
                      class="form-control"
                      id="inputPhone"
                      type="tel"
                      placeholder="Enter your phone number"
                      v-model="currentUser.profile.phone"
                    />
                  </div>

                  <!-- Form Group (location)-->
                  <div class="col-md-12">
                    <label class="small mb-1" for="inputLocation"
                      >Address</label
                    >
                    <input
                      class="form-control"
                      id="inputLocation"
                      type="text"
                      placeholder="Enter your location"
                      v-model="currentUser.profile.address"
                    />
                  </div>
                </div>
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (phone number)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="city">Suburb</label>
                    <input
                      class="form-control"
                      id="city"
                      type="text"
                      placeholder="Enter your city"
                      v-model="currentUser.profile.city"
                    />
                  </div>
                  <!-- Form Group (birthday)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="province">Post Code</label>
                    <input
                      class="form-control"
                      id="province"
                      type="text"
                      name="province"
                      placeholder="Enter your province"
                      v-model="currentUser.profile.post_code"
                    />
                  </div>
                </div>
                <div class="row gx-3 mb-3">
                  <!-- Form Group (phone number)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="city">City</label>
                    <input
                      class="form-control"
                      id="city"
                      type="text"
                      placeholder="Enter your city"
                      v-model="currentUser.profile.city"
                    />
                  </div>
                  <!-- Form Group (birthday)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="province">Province</label>
                    <input
                      class="form-control"
                      id="province"
                      type="text"
                      name="province"
                      placeholder="Enter your province"
                      v-model="currentUser.profile.province"
                    />
                  </div>
                </div>

                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (phone number)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="race">Race</label>
                    <input
                      class="form-control"
                      id="race"
                      type="textr"
                      placeholder="Enter your race"
                      v-model="currentUser.profile.race"
                    />
                  </div>
                  <!-- Form Group (birthday)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputBirthday"
                      >Birthday</label
                    >
                    <input
                      class="form-control"
                      id="inputBirthday"
                      type="date"
                      name="birthday"
                      placeholder="Enter your birthday"
                      v-model="currentUser.profile.birthday"
                    />
                  </div>
                </div>
                <!-- Save changes button-->
                <button @click="updateUser" class="btn bg-6" type="button">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import callAPI from '../axios-api';
import { Vue } from 'vue-class-component';
import CustomToast from './CustomToast.vue';
export default {
  name: 'userProfile',
  props: {
    currentUser: {
      user: {
        type: Object,
        required: false,
      },
      profile: {
        type: Object,
        required: false,
      },
    },
  },

  data() {
    return {
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
    };
  },
  components: {
    CustomToast,
  },
  methods: {
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },
    updateUser() {
      const userFormData = new FormData();
      userFormData.append('first_name', this.currentUser.user.first_name);
      userFormData.append('last_name', this.currentUser.user.last_name);
      userFormData.append('email', this.currentUser.user.email);

      const profileFormData = new FormData();
      profileFormData.append('phone', this.currentUser.profile.phone);
      profileFormData.append('address', this.currentUser.profile.address);
      profileFormData.append('city', this.currentUser.profile.city);
      profileFormData.append('province', this.currentUser.profile.province);
      profileFormData.append('race', this.currentUser.profile.race);
      profileFormData.append('birthday', this.currentUser.profile.birthday);

      callAPI
        .put(
          '/api/v1/account/current/',
          {
            user: this.currentUser.user,
            profile: this.currentUser.profile,
          },
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((response) => {
                //console.log(response);
                this.updateUser();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status == 200) {
            this.customToast(
              'Success',
              'Account details updated successfully',
              '',
              true
            );
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((response) => {
                //console.log(response);
                this.updateUser();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
  },
};
</script>
