<style scoped>
@import url('https://fonts.googleapis.com/css?family=Oswald');
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  background-color: #dadde6;
  font-family: arial;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #fec500;
  padding-left: 10px;
  margin-bottom: 30px;
}

.row {
  overflow: hidden;
}

.card {
  display: table-row;
  width: 100%;
  background-color: #fff;
  color: #989898;
  margin-bottom: 10px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  position: relative;
}

.date {
  display: table-cell;
  width: 25%;
  position: relative;
  text-align: center;
  border-right: 2px dashed #dadde6;
}

.date:before,
.date:after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-color: #eeeeee;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 1;
  border-radius: 50%;
}

.date:after {
  top: auto;
  bottom: -15px;
}

.date time {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.date time span {
  display: block;
}

.date time span:first-child {
  color: #2b2b2b;
  font-weight: 600;
  font-size: 250%;
}

.date time span:last-child {
  text-transform: uppercase;
  font-weight: 600;
  margin-top: -10px;
}

.card-cont {
  display: table-cell;
  width: 75%;
  font-size: 85%;
  padding: 10px 10px 30px 50px;
}

.card-cont h3 {
  color: #3c3c3c;
  font-size: 130%;
}

.row:last-child .card:last-of-type .card-cont h3 {
  text-decoration: line-through;
}

.card-cont > div {
  display: table-row;
}

.card-cont .even-date i,
.card-cont .even-info i,
.card-cont .even-date time,
.card-cont .even-info p {
  display: table-cell;
}

.card-cont .even-date i,
.card-cont .even-info i {
  padding: 5% 5% 0 0;
}

.card-cont .even-info p {
  padding: 30px 50px 0 0;
}

.card-cont .even-date time span {
  display: block;
}

.redeemed {
  background-color: #037fdd;
}

.available {
  background-color: green;
}

.refunded {
  background-color: orange;
}

.cancelled {
  background-color: #f8504c;
}
.card-cont a {
  display: block;
  text-decoration: none;
  width: 80px;
  height: 30px;

  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

@media screen and (max-width: 860px) {
  .card {
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 10px;
  }
  .card + .card {
    margin-left: 0;
  }
  .card-cont .even-date,
  .card-cont .even-info {
    font-size: 75%;
  }
}

.admit-one {
  position: absolute;
  color: darkgray;
  height: 250px;
  padding: 5 10px;
  letter-spacing: 0.15em;
  display: flex;
  text-align: center;
  justify-content: space-around;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}

.admit-one span:nth-child(2) {
  color: white;
  font-weight: 700;
}
.right {
  width: 250px;
}

.image {
  height: 150px;
  width: 150px;
  padding-top: 2%;

  margin-right: 2%;

  background-size: contain;
  /*opacity: 0.85;*/
}
</style>

<template>
  <section class="container">
    <div class="row">
      <article class="card fl-left" v-for="ticket in tickets">
        <div
          class="modal fade"
          :id="'qr-code-' + ticket.id"
          tabindex="-1"
          aria-labelledby="qrCodeModalLabel"
          aria-hidden="true"
        >
          <CustomerQRCODE :qr_code_url="ticket.qr_code_url" />
        </div>
        <div class="image position-absolute top-0 end-0">
          <div
            class="image d-none d-md-inline-block"
            :style="{
              backgroundImage: `url(${ticket.qr_code_url})`,
            }"
          ></div>
        </div>
        <section class="date">
          <p class="admit-one">
            <span>{{ ticket.ticket_type }}</span>
          </p>

          <time datetime="23th feb">
            <span>{{ getEventDay(ticket.start_date) }}</span
            ><span>{{ getEventMonth(ticket.start_date) }}</span>
          </time>
        </section>

        <section class="card-cont">
          <small>Ticket ID {{ ticket.ticket_num }}</small>
          <h3>{{ ticket.event_name }}</h3>
          <h3>at {{ ticket.event_venue }}</h3>

          <div class="even-date">
            <i class="fa fa-calendar"> </i>
            <time>
              <span>
                {{ formatEventDates(ticket.start_date, ticket.end_date) }}</span
              >
              <span
                >From {{ timeFormat(ticket.start_date) }} to
                {{ timeFormat(ticket.end_date) }}</span
              >
              <span>doors open at : {{ ticket.doors_open }}</span>
            </time>
          </div>

          <div class="even-info">
            <i class="fa fa-map-marker"></i>
            <p>{{ ticket.venue_loc }}</p>
          </div>

          <a
            data-bs-toggle="modal"
            :data-bs-target="'#qr-code-' + ticket.id"
            :class="ticket.status"
            href="#"
            >{{ ticket.status }}</a
          >
        </section>
      </article>
    </div>

    <!--  <div class="row">
      <article class="card fl-left">
        <section class="date">
          <time datetime="23th feb"> <span>23</span><span>feb</span> </time>
        </section>
        <section class="card-cont">
          <small>dj khaled</small>
          <h3>music kaboom festivel</h3>
          <div class="even-date">
            <i class="fa fa-calendar"></i>
            <time>
              <span>wednesday 28 december 2014</span>
              <span>08:55pm to 12:00 am</span>
            </time>
          </div>
          <div class="even-info">
            <i class="fa fa-map-marker"></i>
            <p>nexen square for people australia, sydney</p>
          </div>
          <a href="#">booked</a>
        </section>
      </article>
      <article class="card fl-left">
        <section class="date">
          <time datetime="23th feb"> <span>23</span><span>feb</span> </time>
        </section>
        <section class="card-cont">
          <small>dj khaled</small>
          <h3>hello dubai festivel</h3>
          <div class="even-date">
            <i class="fa fa-calendar"></i>
            <time>
              <span>wednesday 28 december 2014</span>
              <span>08:55pm to 12:00 am</span>
            </time>
          </div>
          <div class="even-info">
            <i class="fa fa-map-marker"></i>
            <p>nexen square for people australia, sydney</p>
          </div>
          <a href="#">cancel</a>
        </section>
      </article>
    </div> -->
  </section>
</template>

<script>
import CustomerQRCODE from '@/components/CustomerQRCODE.vue';
export default {
  name: 'TicketsBox',
  baseURL: process.env.VUE_APP_BACKEND_URL,
  props: {
    tickets: {
      type: Array,
      required: false,
    },
  },
  components: {
    CustomerQRCODE,
  },
  methods: {
    getEventMonth(date) {
      const month = new Date(date).toLocaleString('en-ZA', { month: 'short' });
      return month;
    },
    getEventDay(date) {
      const day = new Date(date).toLocaleString('en-ZA', { day: 'numeric' });
      return day;
    },

    formatEventDates(from, to) {
      const from_date = new Date(from);
      const to_date = new Date(to);
      const fromDateString = from_date.toLocaleString('en-ZA', {
        dateStyle: 'medium',
      });
      const toDateString = to_date.toLocaleString('en-ZA', {
        dateStyle: 'medium',
      });
      const fromDateOnly = new Date(fromDateString);
      const toDateOnly = new Date(toDateString);

      if (fromDateOnly.getTime() === toDateOnly.getTime()) {
        return (
          ' . ' + from_date.toLocaleString('en-ZA', { dateStyle: 'medium' })
        );
      } else {
        return (
          ' : ' +
          from_date.toLocaleDateString('en-ZA', { dateStyle: 'medium' }) +
          ' to ' +
          to_date.toLocaleDateString('en-ZA', { dateStyle: 'medium' })
        );
      }
    },

    timeFormat(date) {
      const eventTime = new Date(date).toLocaleTimeString('en-ZA', {
        timeStyle: 'medium',
      });
      return eventTime;
    },

    formatSmallDate(date) {
      return ' ' + date;
    },
  },

  computed: {
    ticketStatusClass(status) {
      return this.events;
    },
  },
};
</script>
