<template>
  <ResetConfirm />
</template>

<script>
import axios from 'axios';
import ResetConfirm from '@/components/ResetConfirm.vue';
export default {
  name: 'PasswordRestConfirm',
  data() {
    return {
      islogin: true,
    };
  },
  components: {
    ResetConfirm,
  },
};
</script>
