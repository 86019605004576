<template>
  <Login v-if="islogin" />
</template>

<script>
import axios from 'axios';
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
export default {
  name: 'LoginPage',
  data() {
    return {
      islogin: true,
    };
  },
  components: {
    Login,
    Register,
  },
};
</script>
