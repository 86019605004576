<style scoped>
body {
  background: #dcdcdc;
  margin-top: 20px;
}
.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.social-links li a {
  border-radius: 50%;
  color: rgba(121, 121, 121, 0.8);
  display: inline-block;
  height: 30px;
  line-height: 27px;
  border: 2px solid rgba(121, 121, 121, 0.5);
  text-align: center;
  width: 30px;
}

.social-links li a:hover {
  color: #797979;
  border: 2px solid #797979;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.text-pink {
  color: #ff679b !important;
}
.btn-rounded {
  border-radius: 2em;
}
.text-muted {
  color: #98a6ad !important;
}
h4 {
  line-height: 22px;
  font-size: 18px;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #6f5b92;
  padding-left: 10px;
  margin-bottom: 30px;
}

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #f78d5e;
  color: #fff;
}

.bg-5:hover {
  background-color: #f78d5e;
  color: #fff;
}
</style>

<template>
  <!-- Modal -->

  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add New Company Rep</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-xl-12">
          <!-- Account details card-->
          <div class="card mb-4">
            <div class="card-header">Rep Account Details</div>
            <div class="card-body">
              <form>
                <!-- Form Group (username)-->
                <!-- Form Row-->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (first name)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputFirstName"
                      >First name *</label
                    >
                    <input
                      class="form-control"
                      id="inputFirstName"
                      type="text"
                      placeholder="Enter rep's first name"
                      v-model="currentUser.user.first_name"
                      required="required"
                    />
                  </div>
                  <!-- Form Group (last name)-->
                  <div class="col-md-6">
                    <label class="small mb-1" for="inputLastName"
                      >Last name *</label
                    >
                    <input
                      class="form-control"
                      id="inputLastName"
                      type="text"
                      placeholder="Enter rep's last name"
                      v-model="currentUser.user.last_name"
                      required="required"
                    />
                  </div>
                </div>
                <!-- Form Row        -->
                <div class="row gx-3 mb-3">
                  <!-- Form Group (email address)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email address *</label
                    >
                    <input
                      class="form-control"
                      id="inputEmailAddress"
                      type="email"
                      placeholder="Enter rep's email address"
                      v-model="currentUser.user.email"
                      required
                    />
                  </div>

                  <div class="mb-3">
                    <label class="small mb-1" for="inputPhone"
                      >Phone number *</label
                    >
                    <input
                      class="form-control"
                      id="inputPhone"
                      type="tel"
                      placeholder="Enter rep's phone number"
                      v-model="currentUser.profile.phone"
                      required="true"
                    />
                  </div>
                </div>

                <!-- Save changes button-->
                <button @click="doUserInvite" class="btn bg-6" type="button">
                  Send Invite
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
import callAPI from '../axios-api';
import axios from 'axios';
import { Vue } from 'vue-class-component';
import CustomToast from './CustomToast.vue';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'RepProfile',
  props: {
    company: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },
      success: '',
      errors: [],

      currentUser: {
        user: {},
        profile: {},
      },
    };
  },
  components: {
    CustomToast,
  },
  methods: {
    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },
    isValidForm() {
      let isValid = true;
      if (
        this.currentUser.user.first_name == null ||
        this.currentUser.user.first_name == '' ||
        this.currentUser.user.last_name == '' ||
        this.currentUser.user.last_name == null
      ) {
        this.errors.push('First and Last name are required');
        isValid = false;
      }

      if (
        this.currentUser.user.email == '' ||
        this.currentUser.user.email == null
      ) {
        this.errors.push('Email is required');
        isValid = false;
      }

      if (
        this.currentUser.profile.phone === '' ||
        this.currentUser.profile.phone == null
      ) {
        this.errors.push('Phone number is required');
        isValid = false;
      }

      return isValid;
    },
    doUserInvite() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;
      if (this.isValidForm()) {
        this.$store.state.isLoading = true;
        axios
          .post(baseURL + '/api/v1/account/invite/', {
            headers: {
              Authorization: `JWT ${token}`,
            },
            email: this.currentUser.user.email,
            first_name: this.currentUser.user.first_name,
            last_name: this.currentUser.user.last_name,
            phone: this.currentUser.profile.phone,
            company: this.company,
            invited_by: this.$store.getters.getUserId,
          })
          .then((response) => {
            console.log(response);
            this.customToast(
              'Success',
              'Invite sent successfully. The rep has been notified about this invite.',
              '',

              true
            );
            this.$store.state.isLoading = false;
            this.currentUser.user.first_name = '';
            this.currentUser.user.last_name = '';
            this.currentUser.user.email = '';
            this.currentUser.profile.phone = '';
          })
          .catch((error) => {
            console.log(error);
            this.customToast(
              'Error',
              'Error sending invite.' + error.response.data.detail,
              '',
              true
            );
            this.$store.state.isLoading = false;
          });
      } else {
        //alert(...this.errors);
        this.errors.forEach((error) => {
          this.customToast('Error', error, '', true);
        });
      }

      // this.$store.state.isLoading = true;
    },

    doRegistration() {
      if (this.isValidForm()) {
        this.$store.state.isLoading = true;
        this.$store
          .dispatch('userReg', {
            email: this.currentUser.user.email,
            password: this.generateRandomPassword(16),
            first_name: this.currentUser.user.first_name,
            last_name: this.currentUser.user.last_name,
            birthday: this.currentUser.profile.birthday,
            phone: this.currentUser.profile.phone,
            address: this.currentUser.profile.address,
            city: this.currentUser.profile.city,
            profile_type: 'rep',
          })
          .then(() => {
            this.customToast(
              'Success',
              'Company Rep added successful!. Please wait for the rep to accept the invitation.',
              '',
              true
            );
            this.email = '';
            this.password = '';
            this.first_name = '';
            this.last_name = '';
            this.birthday = '';
            this.phone = '';
            this.city = '';

            this.$store.state.isLoading = false;
            //this.$router.go(-1);
          })
          .catch((err) => {
            this.$store.state.isLoading = false;
            console.log(err.username);
            if (err.username == 'A user with that username already exists.') {
            }
            if (err.length > 1) {
              for (const errorElements of err) {
                for (const [elemnt, errorElement] in errorElements) {
                  this.errors.push(element + ' : ' + errorElement);
                  this.customToast(
                    'Error',
                    'Missing' + element + ' : ' + errorElement,
                    ''
                  );
                }
              }
            } else {
              const errorObj = Object.keys(err.user);
              const errorKey = errorObj[0];
              const errorValue = err.user[errorKey];
              this.customToast(
                'Error',
                'Error in the ' + errorKey + ' field. ' + errorValue,
                '',
                true
              );
            }
          });
      } else {
        alert(...this.errors);
      }
    },
    updateUser() {
      const userFormData = new FormData();
      userFormData.append('first_name', this.currentUser.user.first_name);
      userFormData.append('last_name', this.currentUser.user.last_name);
      userFormData.append('email', this.currentUser.user.email);

      const profileFormData = new FormData();
      profileFormData.append('phone', this.currentUser.profile.phone);
      profileFormData.append('address', this.currentUser.profile.address);
      profileFormData.append('city', this.currentUser.profile.city);
      profileFormData.append('province', this.currentUser.profile.province);
      profileFormData.append('race', this.currentUser.profile.race);
      profileFormData.append('birthday', this.currentUser.profile.birthday);

      callAPI
        .put(
          '/api/v1/account/current/',
          {
            user: this.currentUser.user,
            profile: this.currentUser.profile,
          },
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((response) => {
                //console.log(response);
                this.updateUser();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status == 200) {
            this.customToast(
              'Success',
              'Account details updated successfully',
              '',
              true
            );
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', this.$store.getters.getRefreshToken)
              .then((response) => {
                //console.log(response);
                this.updateUser();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    generateRandomPassword(length) {
      const charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
      let password = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }

      return password;
    },
  },
};
</script>
