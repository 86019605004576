<style>
.profile-pic-wrapper {
  width: 100%;
}
.pic-holder {
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 300px;
  height: 300px;

  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pic-holder .pic {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder .upload-file-block {
  cursor: pointer;
}

.pic-holder:hover .upload-file-block,
.uploadProfileInput:focus ~ .upload-file-block {
  opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
  display: none;
}

.pic-holder.uploadInProgress .upload-loader {
  opacity: 1;
}

/* Snackbar css */
.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 14px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span class="event-icon">Event</span>
        </h4>

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="clearData"
        ></button>
      </div>
      <div class="modal-body">
        <form id="add-event">
          <div class="modal-body">
            <h4 v-if="event.name">Edit {{ eventName }}</h4>
            <h4 v-else>Add New Event</h4>

            <div class="row gx-3 mb-3">
              <!-- Form Group (email address)-->
              <div class="col-md-6">
                <div class="profile-pic-wrapper">
                  <div class="pic-holder">
                    <!-- uploaded pic shown here -->
                    <img id="profilePic" class="pic" :src="eventImage" />

                    <input
                      class="uploadProfileInput"
                      type="file"
                      @change="onChangeFileUpload($event)"
                      name="profile_pic"
                      id="event-image"
                      accept="image/*"
                      style="opacity: 0"
                    />
                    <label for="event-image" class="upload-file-block">
                      <div class="text-center">
                        <div class="mb-2">
                          <i class="fa fa-camera fa-2x"></i>
                        </div>
                        <div class="text-uppercase">
                          Upload <br />
                          Event Image
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label>Event name</label>
                <input
                  type="text"
                  id="event-name"
                  class="form-control event-title"
                  name="inputName"
                  v-model="eventName"
                  @input="updateSlug"
                  required
                />
                <i>{{ slugify(eventName) }}</i>
                <br />
                <label>Event Short Description</label>
                <textarea
                  class="form-control"
                  v-model="eventShortDesc"
                  name="inputShortDesc"
                  id="short-desc"
                  required
                ></textarea>
                <br />

                <label>Event Description</label>
                <textarea
                  class="form-control"
                  v-model="eventDesc"
                  name="inputDesc"
                  id="desc"
                  required
                ></textarea>
              </div>
            </div>
            <div class="row gx-3 mb-3">
              <!-- Form Group (start time)-->
              <div class="col-md-6">
                <label class="small mb-1" for="inputCaategory"
                  >Event Category</label
                >
                <select
                  class="form-control"
                  name="inputCategories"
                  id="category"
                  v-model="eventCategory"
                  required
                >
                  <option></option>
                  <option v-for="category in categories" :value="category.id">
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <!-- Form Group (end time)-->
              <div class="col-md-6">
                <label class="small mb-1" for="inputVenue">Venue</label>
                <select
                  class="form-control"
                  name="inputVenues"
                  id="venue"
                  v-model="eventVenue"
                  required
                >
                  <option></option>
                  <option v-for="venue in venues" :value="venue.id">
                    {{ venue.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row gx-3 mb-3">
              <!-- Form Group (start date)-->
              <div class="col-md-6">
                <label class="small mb-1" for="inputStartDate"
                  >Start Date and Time</label
                >

                <flat-pickr
                  placeholder="Enter event start date"
                  class="form-control"
                  :config="dateFormat"
                  v-model="eventStartDate"
                  id="start-date"
                  required
                />
              </div>
              <!-- Form Group (end date)-->
              <div class="col-md-6">
                <label class="small mb-1" for="inputEndDate"
                  >End Date and Time</label
                >
                <flat-pickr
                  placeholder="Enter event start date"
                  class="form-control"
                  :config="dateFormat"
                  v-model="eventEndDate"
                  id="end-date"
                  required
                />
              </div>
            </div>
            <div class="row gx-3 mb-3">
              <!-- Form Group (start date)-->
              <div class="col-md-6">
                <label class="small mb-1" for="inputDoorsOpen"
                  >Doors Open</label
                >

                <flat-pickr
                  placeholder="Enter venue dorr open time"
                  class="form-control"
                  :config="timeOnly"
                  v-model="eventDoorsOpen"
                  id="doors-open"
                  required
                />
              </div>

              <div class="col-md-6">
                <label class="small mb-1" for="status">Status</label>
                <select
                  class="form-control"
                  name="status"
                  id="status"
                  v-model="eventSelectedStatus"
                >
                  <option></option>
                  <option
                    v-for="option in statusOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="row gx-3 mb-3"
              v-for="(ticket, index) in tickets"
              :key="`ticket-${index}`"
            >
              <!-- Form Group (start date)-->
              <div class="col-md-6">
                <label class="small mb-1" for="inputTicketType"
                  >Ticket Type</label
                >

                <select
                  class="form-control"
                  name="inputTicketType"
                  id="inputTicketType"
                  v-model="ticket.ticket_type"
                  placeholder=" Select ticket category"
                  required
                >
                  <option></option>
                  <option
                    v-for="ticket_category in ticketCategories"
                    :value="ticket_category.id"
                  >
                    {{ ticket_category.name }}
                  </option>
                </select>
              </div>

              <div class="col-md-6">
                <label class="small mb-1" for="price">Ticket Price</label>
                <div style="display: flex; align-items: center">
                  <input
                    type="number"
                    class="form-control"
                    v-model="ticket.price"
                  />
                  <!--Add Svg Icon-->
                  <svg
                    @click="addField(input, tickets)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="green"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    />
                  </svg>
                  <!--Remove Svg Icon-->
                  <svg
                    v-show="tickets.length > 1"
                    @click="removeField(index, tickets)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="#EC4899"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Form Row-->

            <!--<div class="form-group">
              <label>Event Color</label>
              <select class="form-control" name="ecolor">
                <option value="fc-bg-default">fc-bg-default</option>
                <option value="fc-bg-blue">fc-bg-blue</option>
                <option value="fc-bg-lightgreen">fc-bg-lightgreen</option>
                <option value="fc-bg-pinkred">fc-bg-pinkred</option>
                <option value="fc-bg-deepskyblue">fc-bg-deepskyblue</option>
              </select>
            </div>-->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="clearData"
            >
              Close
            </button>
            <button
              v-if="event.name"
              @click="saveEvent('update')"
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-primary"
            >
              Update Event
            </button>
            <button
              v-else
              @click="saveEvent('create')"
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-primary"
            >
              Create Event
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <CustomToast
    :toastInfo="{
      header: toastInfo.header,
      message: toastInfo.message,
      smallText: toastInfo.smallText,
      show: toastInfo.show,
    }"
  />
</template>

<script>
//import flatpickr from 'flatpickr';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import CustomToast from './CustomToast.vue';
import 'flatpickr/dist/flatpickr.css';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'EventEditor',
  emits: ['clearData'],
  props: {
    event: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dateFormat: {
        enableTime: true,
        allowInput: true,
        altInput: true,
        defaultHour: 12,
        dateFormat: 'Y-m-d H:i',
      },
      timeOnly: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        defaultHour: 10,
      },
      categories: [],
      venues: [],
      tickets: [{ ticket_type: null, price: null }],
      toastInfo: {
        header: '',
        message: '',
        smallText: '',
        show: false,
      },

      eventImage: '',
      eventName: '',
      eventSlug: '',
      eventCategorySlug: '',
      eventShortDesc: '',
      eventDesc: '',
      eventCategory: '',
      eventVenue: '',
      eventStartDate: '',
      eventEndDate: '',
      eventDoorsOpen: '',
      companyProfile: {},
      eventSelectedStatus: '',

      ticketCategories: [],
      emptyTicket: {
        ticket_type: null,
        price: null,
      },
    };
  },

  components: {
    flatPickr,
    CustomToast,
  },
  methods: {
    async uploadLogo() {
      document.addEventListener('change', async (event) => {
        const triggerInput = event.target;

        if (!triggerInput.classList.contains('uploadProfileInput')) {
          return;
        }

        const holder = triggerInput.closest('.pic-holder');
        const wrapper = triggerInput.closest('.profile-pic-wrapper');
        const alerts = wrapper.querySelectorAll('[role="alert"]');

        for (const alert of alerts) {
          alert.remove();
        }

        triggerInput.blur();
        const files = triggerInput.files || [];

        if (
          !files.length ||
          !window.FileReader ||
          !/^image/.test(files[0].type)
        ) {
          displayErrorMessage(wrapper);
          return;
        }

        try {
          const imgDataURL = await readImageAsDataURL(files[0]);
          holder.classList.add('uploadInProgress');
          holder.querySelector('.pic').src = imgDataURL;
          wrapper.querySelector('.pic').src = imgDataURL;
          displaySuccessMessage(wrapper);
        } catch (error) {
          displayErrorMessage(wrapper);
        }
      });

      function readImageAsDataURL(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve(reader.result);
          };

          reader.onerror = () => {
            reject(new Error('Failed to read image.'));
          };

          reader.readAsDataURL(file);
        });
      }

      function displayErrorMessage(wrapper) {
        const invalidAlert = document.createElement('div');
        invalidAlert.classList.add(
          'alert',
          'alert-danger',
          'd-inline-block',
          'p-2',
          'small'
        );
        invalidAlert.setAttribute('role', 'alert');
        invalidAlert.textContent = 'Please choose a valid image.';
        wrapper.appendChild(invalidAlert);

        setTimeout(() => {
          invalidAlert.remove();
        }, 3000);
      }

      function displaySuccessMessage(wrapper) {
        const successAlert = document.createElement('div');
        successAlert.classList.add('snackbar', 'show');
        successAlert.setAttribute('role', 'alert');
        successAlert.innerHTML =
          '<i class="fa fa-check-circle text-success"></i> Event image updated successfully';
        wrapper.appendChild(successAlert);

        setTimeout(() => {
          successAlert.remove();
        }, 3000);
      }
    },

    onChangeFileUpload(event) {
      this.eventImage = event.target.files[0];
    },
    getAllVenues() {
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      axios.get(baseURL + '/api/v1/venues/').then((response) => {
        this.venues = response.data;
      });
    },
    getAllcategories() {
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      axios.get(baseURL + '/api/v1/events/categories/').then((response) => {
        this.categories = response.data;
      });
    },
    clearData() {
      this.$emit('clearData');
    },

    createEvent() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;
      this.eventName = document.getElementById('event-name').value;
      this.eventShortDesc = document.getElementById('short-desc').value;
      this.eventDesc = document.getElementById('desc').value;
      this.eventCategory = document.getElementById('category').value;
      this.eventVenue = document.getElementById('venue').value;
      this.eventStartDate = document.getElementById('start-date').value;
      this.eventEndDate = document.getElementById('end-date').value;
      this.eventDoorsOpen = document.getElementById('doors-open').value;

      let formData = new FormData();
      formData.append('name', this.eventName);
      formData.append('slug', this.slugify(this.eventName));
      formData.append('short_description', this.eventShortDesc);
      formData.append('description', this.eventDesc);
      formData.append('category', this.eventCategory);
      formData.append('venue', this.eventVenue);
      formData.append('start_date', this.eventStartDate);
      formData.append('end_date', this.eventEndDate);
      formData.append('doors_open', this.eventDoorsOpen);
      formData.append('image', this.eventImage);
      formData.append('company', this.companyProfile.id);
      formData.append('status', 'draft');
      //console.log(formData);

      axios
        .post(
          baseURL + '/api/v1/events/',

          formData,

          {
            headers: {
              Authorization: `JWT ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status == 201) {
            for (let i = 0; i < this.tickets.length; i++) {
              this.createTicket(
                response.data.id,
                this.tickets[i].ticket_type,
                this.tickets[i].price
              );
            }
            this.customToast = {
              header: 'Success',
              message: 'Event created successfully',
              smallText: '',
              show: true,
            };
            this.clearData();
          } else if ((response.status = 401)) {
            this.$store.dispatch('reAuthorizeApi', refreshToken).then((res) => {
              this.createEvent();
            });
          } else {
            this.customToast = {
              header: 'Error',
              message: response.data.detail,
              smallText: '',
              show: true,
            };
          }
        })
        .catch((error) => {
          this.customToast = {
            header: 'Error',
            message: error.response.data.detail,
            smallText: '',
            show: true,
          };
        });
    },
    updateEvent() {
      this.$store.state.isLoading = true;
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;
      const fromDate = new Date(this.eventStartDate).toLocaleDateString(
        'en-ZA'
      );
      const toDate = new Date(this.eventEndDate).toLocaleDateString('en-ZA');

      let formData = new FormData();
      if (this.eventImage !== this.event.event_img) {
        formData.append('image', this.eventImage);
      }
      formData.append('name', this.eventName);
      formData.append('new_slug', this.eventSlug);
      formData.append('slug', this.event.slug); //We still need to send to old slug to the backend. This is used to identify the event to be updated .
      formData.append('short_description', this.eventShortDesc);
      formData.append('description', this.eventDesc);
      formData.append('category', this.eventCategory);
      formData.append('venue', this.eventVenue);
      formData.append('start_date', this.eventStartDate);
      formData.append('end_date', this.eventEndDate);
      formData.append('doors_open', this.eventDoorsOpen);

      formData.append('company', this.companyProfile.id);
      formData.append('status', this.eventSelectedStatus);
      //console.log(formData);

      axios
        .put(
          baseURL +
            `/api/v1/events/${this.eventCategorySlug}/${this.event.slug}/`, //We use old slug because we need to identify the event to be updated

          formData,

          {
            headers: {
              Authorization: `JWT ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.status == 200) {
            for (let i = 0; i < this.tickets.length; i++) {
              this.createTicket(
                response.data.id,
                this.tickets[i].ticket_type,
                this.tickets[i].price
              );
            }
            this.customToast = {
              header: 'Success',
              message: 'Event updated successfully',
              smallText: '',
              show: true,
            };
            this.clearData();
          } else if ((response.status = 401)) {
            this.$store.state.isLoading = false;
            this.$store.dispatch('reAuthorizeApi', refreshToken).then((res) => {
              this.updateEvent();
            });
          } else {
            this.$store.state.isLoading = false;
            this.customToast = {
              header: 'Error',
              message: response.data.detail,
              smallText: '',
              show: true,
            };
          }
        })
        .catch((error) => {
          this.customToast = {
            header: 'Error',
            message: 'You do not have permission to perform this action',
            smallText: '',
            show: true,
          };
          this.$store.state.isLoading = false;
        });
    },
    saveEvent(action) {
      if (this.$store.getters.isTokenExpired) {
        if (action == 'update') {
          this.updateEvent();
        } else {
          this.createEvent();
        }
      } else {
        this.$store
          .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
          .then((res) => {
            if (action == 'update') {
              this.updateEvent();
            } else {
              this.createEvent();
            }
          });
      }
    },
    getCompanyProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;

      axios
        .get(baseURL + '/api/v1/account/company/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.companyProfile = res.data.company;
            this.$store.commit('setMerchantSlug', res.data.company.slug);
          } else if (res.status == 401) {
            this.$store.dispatch('reAuthorizeApi', refreshToken).then((res) => {
              this.getCompanyProfile();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.customToast = {
            header: 'Error',
            message: err.response.data.detail,
            smallText: '',
            show: true,
          };
          this.$store.state.isLoading = false;
        });

      this.$store.state.isLoading = false;
    },
    slugify(input) {
      if (!input) return '';

      // make lower case and trim
      var slug = input.toLowerCase().trim();

      // remove accents from charaters
      slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      // replace invalid chars with spaces
      slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

      // replace multiple spaces or hyphens with a single hyphen
      slug = slug.replace(/[\s-]+/g, '-');

      return slug;
    },
    updateSlug() {
      this.eventSlug = this.slugify(this.eventName);
    },
    updateSelectedStatus(event) {
      this.eventSelectedStatus = event.target.value;
    },
    addField(value, fieldType) {
      fieldType.push({ value: '' });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    async getEventTickets() {
      const category_slug = this.event.category_slug;
      const event_slug = this.event.slug;
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      this.$store.state.isLoading = true;
      await axios
        .get(baseURL + `/api/v1/tickets/${category_slug}/${event_slug}`)
        .then((response) => {
          this.tickets = response.data.map((ticket) => ({
            ...ticket,
          }));
          if (this.tickets.length == 0) {
            this.tickets.push(this.emptyTicket);
          }
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.customToast = {
            header: 'Error',
            message: error.response.data.detail,
            smallText: '',
            show: true,
          };
        });
    },
    getTicketCategories() {
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      axios.get(baseURL + '/api/v1/tickets/categories/').then((response) => {
        this.ticketCategories = response.data;
      });
    },

    createTicket(event_id, type, price) {
      this.$store.state.isLoading = true;
      const token = this.$store.state.token;
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      axios
        .post(baseURL + `/api/v1/tickets/${event_id}/`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
          ticket_type: type,
          price: price,
          event: event_id,
        })
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.status == 201) {
            this.tickets.push(response.data);
          } else {
            this.customToast = {
              header: 'Error',
              message: response.data.detail,
              smallText: '',
              show: true,
            };
          }
        })
        .catch((error) => {
          this.customToast = {
            header: 'Error',
            message: error.response.data.detail,
            smallText: '',
            show: true,
          };
        });
    },
    deleteTicket(ticket_id) {
      this.$store.state.isLoading = true;
      const token = this.$store.state.token;
      const baseURL = process.env.VUE_APP_BACKEND_URL;
      axios
        .delete(baseURL + `/api/v1/tickets/${ticket_id}/`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.status == 204) {
            this.customToast = {
              header: 'Success',
              message: 'Ticket deleted successfully',
              smallText: '',
              show: true,
            };
          } else {
            this.customToast = {
              header: 'Error',
              message: response.data.detail,
              smallText: '',
              show: true,
            };
          }
        })
        .catch((error) => {
          this.customToast = {
            header: 'Error',
            message: error.response.data.detail,
            smallText: '',
            show: true,
          };
        });
    },

    customToast(header, message, smallText) {
      this.toastInfo.show = true;
      this.toastInfo.header = header;
      this.toastInfo.message = message;
      this.toastInfo.smallText = smallText;
      setTimeout(() => {
        this.toastInfo.show = false;
      }, 5000);
    },
  },
  mounted() {
    this.uploadLogo();
    if (this.$store.getters.isTokenExpired) {
      this.$store
        .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
        .then((res) => {
          this.getAllVenues();
          this.getAllcategories();
          this.getCompanyProfile();
          this.getTicketCategories();
        });
    } else {
      this.getAllVenues();
      this.getAllcategories();
      this.getCompanyProfile();
      this.getTicketCategories();
    }
  },
  created() {},
  computed: {
    statusOptions() {
      // Define status choices based on your Django model
      const statusChoices = [
        { value: 'draft', label: 'Draft' },
        { value: 'published', label: 'Published' },
        { value: 'cancelled', label: 'Cancelled' },
        { value: 'postponed', label: 'Postponed' },
        { value: 'rescheduled', label: 'Rescheduled' },
        { value: 'closed', label: 'Closed' },
      ];
      return statusChoices;
    },
  },

  watch: {
    event: {
      handler(newEvent) {
        // Update data elements when 'event' prop is updated
        this.eventName = newEvent.name;
        this.eventSlug = newEvent.slug;
        this.eventShortDesc = newEvent.short_description;
        this.eventDesc = newEvent.description;
        this.eventCategory = newEvent.category;
        this.eventCategorySlug = newEvent.category_slug;
        this.eventVenue = newEvent.venue;
        this.eventStartDate = newEvent.start_date;
        this.eventEndDate = newEvent.end_date;
        this.eventDoorsOpen = newEvent.doors_open;
        this.eventSelectedStatus = newEvent.status;
        this.eventImage = newEvent.image;
        if (
          this.event.name !== undefined &&
          this.event.name !== null &&
          this.event.name !== ''
        ) {
          this.getEventTickets();
        }

        // Update other data properties similarly
      },
      deep: true, // Watch for nested property changes if necessary
    },
  },
};
</script>
