<style>
.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.bg-1 {
  background-color: #4e4066;
}

.bg-2 {
  background-color: #6f5b92;
}

.bg-3 {
  background-color: #f78d5e;
}

.bg-4 {
  background-color: #fa976c;
}

.bg-5 {
  background-color: #b65c32;
}

.bg-6 {
  background-color: #ffc541;
}

.bg-7 {
  background-color: #4e4066;
}

.bg-8 {
  background-color: #6f5b92;
}

.bg-9 {
  background-color: #f78d5e;
}

.bg-6:hover {
  background-color: #6f5b92;
  color: #fff;
}
</style>
<template>
  <div class="container text-center">
    <div class="row">
      <section style="background-color: #f3f6f8">
        <div class="container py-5">
          <div class="row">
            <div class="col-md-8 col-lg-3 col-xl-4" v-for="event in events">
              <div class="card text-black">
                <img
                  v-bind:src="event.thumbnail"
                  class="card-img-top"
                  v-bind:alt="event.name"
                  style="height: 200px"
                />
                <div class="card-body">
                  <div class="text-center">
                    <h5 class="card-title">{{ event.name }}</h5>
                    <p class="mb-4">{{ event.venue_name }}</p>
                  </div>
                  <div>
                    <div class="d-flex justify-content-between">
                      <span>Date</span><span>{{ event.start_date }}</span>
                    </div>

                    <div class="d-flex justify-content-between">
                      <span>{{ event.city }}</span
                      ><span>{{ event.province }}</span>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between total font-weight-bold mt-4"
                  >
                    <span>{{ event.category_name }}</span
                    ><span></span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center pb-2 mb-1"
                  >
                    <a
                      v-bind:href="`events/${event.category_slug}/${event.event_slug}/`"
                    >
                      <button type="button" class="btn bg-6 button">
                        Buy Ticket
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="">
export default {
  name: 'EventBox',
  props: {
    events: {
      type: Array,
      required: false,
    },
  },
};
</script>
