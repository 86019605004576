<style scoped>
body {
  margin-top: 20px;
  background-color: #f7f7ff;
}
.radius-10 {
  border-radius: 10px !important;
}

.border-info {
  border-left: 5px solid #0dcaf0 !important;
}
.border-danger {
  border-left: 5px solid #fd3550 !important;
}
.border-success {
  border-left: 5px solid #15ca20 !important;
}
.border-warning {
  border-left: 5px solid #ffc107 !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.bg-gradient-scooter {
  background: #17ead9;
  background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
  background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}
.widgets-icons-2 {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  font-size: 27px;
  border-radius: 10px;
}
.rounded-circle {
  border-radius: 50% !important;
}
.text-white {
  color: #fff !important;
}
.ms-auto {
  margin-left: auto !important;
}
.bg-gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.bg-gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-blooker {
  background: #ffdf40;
  background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
  background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #6f5b92;
  padding-left: 10px;
  margin-bottom: 30px;
}
</style>
<template>
  <section style="background-color: #eee">
    <div class="container py-5">
      <!--<ProfileNav />-->
      <ProfileNav />
      <div class="row">
        <div class="col-md-6">
          <h1>Company Orders</h1>
        </div>
        <div class="col-md-6 text-md-end">
          <a href="#!" class="text-body" @click="goBack">
            <i class="fas fa-long-arrow-alt-left me-2"></i>Back To Dashboard
          </a>
        </div>
      </div>
      <div class="container">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-3 border-info"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Orders</p>
                    <h4 class="my-1 text-info">{{ getTotalOrders() }}</h4>
                    <p class="mb-0 font-13">+2.5% from last week</p>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto"
                  >
                    <i class="fa fa-shopping-cart"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-3 border-danger"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Sales</p>
                    <h4 class="my-1 text-danger">{{ getTotalRevenue() }}</h4>
                    <p class="mb-0 font-13">+5.4% from last week</p>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"
                  >
                    <i class="fa fa-dollar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-3 border-success"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Tickets</p>
                    <h4 class="my-1 text-success">{{ this.totalTickets }}</h4>
                    <p class="mb-0 font-13">-4.5% from last week</p>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"
                  >
                    <i class="fa fa-bar-chart"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="card radius-10 border-start border-0 border-3 border-warning"
            >
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0 text-secondary">Total Customers</p>
                    <h4 class="my-1 text-warning">8.4K</h4>
                    <p class="mb-0 font-13">+8.4% from last week</p>
                  </div>
                  <div
                    class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"
                  >
                    <i class="fa fa-users"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <OrderHistory :orders="orders" />
</template>
<script>
import ProfileNav from '@/components/ProfileNav.vue';
import OrderHistory from '@/components/OrderHistory.vue';
import Page404 from '@/components/404.vue';
import ConpanyProfile from '@/components/CompanyProfile.vue';
import axios from 'axios';
import callAPI from '../../axios-api.ts';
const baseURL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'MerchantOrders',
  data() {
    return {
      islogin: true,
      profileType: '',
      currentUser: {},
      userprofile: {},
      companyProfile: {},
      orders: [],
      propCurrentAccount: {
        user: {},
        profile: {},
      },
      totalOrders: 0,
      totalTickets: 0,
      totalRevenue: 0.0,
      totalCustomers: 0,

      activeprofileNavTab: 'profile',
      isLoading: false,
    };
  },
  created() {
    document.title = 'Dashboard Orders | VTIX';
  },
  components: {
    ProfileNav,
    Page404,
    ConpanyProfile,
    OrderHistory,
  },
  watch: {
    $route(to, from) {
      if (to.name == 'MerchantOrders') {
        this.getCompanyProfile();
      }
    },

    $store: {
      handler() {
        this.profileType = this.$store.getters.isProfiletype;
      },
      deep: true,
    },
  },
  methods: {
    doLogout() {
      this.$store.commit('clearToken');
      this.$store.commit('clearCart');
      this.$router.push('/');
    },
    goBack() {
      this.$router.go(-1);
    },

    async getCompanyProfile() {
      const token = this.$store.state.token;
      const refreshToken = this.$store.state.refresh_token;

      this.$store.state.isLoading = true;

      await axios
        .get(baseURL + '/api/v1/account/company/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          this.companyProfile = res.data.company;
          this.$store.commit('setMerchantSlug', res.data.company.slug);
          this.$store.state.isLoading = false;
          this.getOrders();
          //this.getTotalOrders();
          this.getTotalTickets();
          //this.getTotalRevenue();
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.isLoading = false;
        });

      this.$store.state.isLoading = false;
    },
    getOrders() {
      callAPI
        .get(`/api/v1/orders/company/${this.companyProfile.id}/`)
        .then((response) => {
          this.orders = response.data;
        })
        .catch((error) => {
          console.log(error);
          if (err.response.status == 401) {
            this.$store
              .dispatch('reAuthorizeApi', refreshToken)
              .then((res) => {
                this.getOrders();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    getTotalOrders() {
      return this.orders.length;
    },

    getTotalTickets() {
      console.log(this.orders);
      for (let i = 0; i < this.orders.length; i++) {
        this.totalTickets += parseInt(this.orders[i].items);
      }
      return this.totalTickets;
    },

    getTotalRevenue() {
      let ZARRand = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
      });
      for (let i = 0; i < this.orders.length; i++) {
        this.totalRevenue += parseFloat(this.orders[i].total);
      }
      return ZARRand.format(this.totalRevenue);
    },
  },
  mounted() {
    this.profileType = this.$store.getters.isProfiletype;
    if (!this.$store.state.isTokenExpired) {
      this.getCompanyProfile();
    } else {
      this.$store
        .dispatch('reAuthorizeApi', this.$store.state.refresh_token)
        .then(() => {
          this.getCompanyProfile();
        });
    }
  },
};
</script>
