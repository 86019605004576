<template>
  <nav class="nav nav-borders">
    <a
      class="nav-link ms-0"
      :class="isActive == 'dashboard' ? 'active' : ''"
      href="#"
      @click="goToDashboard"
      >Dashboard</a
    >
    <a
      class="nav-link"
      :class="isActive == 'merchantorders' ? 'active' : ''"
      href="orders/"
      @click="goToMerchantOrders"
      >Orders</a
    >
    <a
      class="nav-link"
      :class="isActive == 'tickets' ? 'active' : ''"
      href="#"
      @click="goToTickets"
      >Tickets</a
    >
    <a
      class="nav-link"
      :class="isActive == 'security' ? 'active' : ''"
      href="#"
      @click="goToSecurity"
      >Security</a
    >
    <a
      class="nav-link"
      :class="isActive == 'notifications' ? 'active' : ''"
      href="#"
      @click="goTonotifications"
      >Notifications</a
    >
  </nav>
  <hr class="mt-0 mb-4" />
</template>

<script>
export default {
  name: 'DashboardNav',

  data() {
    return {
      isActive: '',
      activeprofileNavTab: 'dashboard',
    };
  },

  watch: {
    $route(to, from) {
      if (to.name === 'Dashboard') {
        this.isActive = 'dashboard';
      }
      if (to.name === 'merchantorders') {
        this.isActive = 'merchantorders';
      }
      if (to.name === 'Tickets') {
        this.isActive = 'tickets';
      }
      if (to.name === 'Security') {
        this.isActive = 'security';
      }
      if (to.name === 'Notifications') {
        this.isActive = 'notifications';
      }
    },
  },

  methods: {
    goDashboard() {
      //this.isActive = 'profile';
      this.$router.push({ name: 'Dashboard' });
    },
    goToTickets() {
      this.$router.push({ name: 'Tickets' });
    },
    goToMerchantOrders() {
      // this.isActive = 'orders';
      this.$router.push({ name: 'merchantorders' });
      console.log(this.$router.getRoutes());
    },
    goToSecurity() {
      this.isActive = 'security';
    },
    goTonotifications() {
      this.activeprofileNavTab = 'notifications';
    },
  },
};
</script>
